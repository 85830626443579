// Global
import { Text } from '@sitecore-jss/sitecore-jss-nextjs';

const getHighlightText = (text: string, searchTerm: string) => {
  const regex = new RegExp(`(${searchTerm})`, 'gi');
  return text
    .split(regex)
    .map((part, index) =>
      regex.test(part) ? (
        <Text encode={false} field={{ value: part }} key={index} tag="strong" />
      ) : (
        <Text encode={false} field={{ value: part }} key={index} tag="span" />
      )
    );
};

export default getHighlightText;
