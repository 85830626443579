// Global
import { Text } from '@sitecore-jss/sitecore-jss-nextjs';
import React from 'react';
import { tv } from 'tailwind-variants';

// Lib
import { ComponentProps } from 'lib/component-props';
import { ComponentVariants } from 'lib/context/ComponentVariants';
import { useTheme } from 'lib/context/ThemeContext';
import { CardComponents } from 'lib/templates/Feature.Dart.model';
import { ItemEx } from 'lib/templates/_.Sitecore.Override';

// Local
import Container from 'components/authorable/Layout/DartContainer/DartContainer';
import Button from 'helpers/Button/Button';
import ImageWrapper from 'helpers/ImageWrapper/ImageWrapper';
import MultiColorBorder from 'helpers/MultiColorBorder/MultiColorBorder';
import RichTextA11yWrapper from 'helpers/RichTextA11yWrapper/RichTextA11yWrapper';
import LegalDisclaimer from 'helpers/LegalDisclaimer/LegalDisclaimer';
import SVG from 'helpers/SVG/SVG';
import fallback from 'lib/fallback/fallback';

export type GenericCard = ItemEx & CardComponents.GenericCard.GenericCardItem;

export type GenericCardProps = ComponentProps & CardComponents.GenericCard.GenericCardsList;

type AlignCTA = 'BottomCenter' | 'TopRight' | undefined;

type CardOrientation = 0 | 4 | 3 | 2 | undefined;

const tailwindVariants = tv({
  defaultVariants: {
    cardOrientation: 0,
  },
  slots: {
    base: [
      'relative',
      'py-components-generic-card-listing-spacing-small-padding-y',
      'px-components-generic-card-listing-spacing-small-padding-x',
      'lg:py-components-article-card-listing-spacing-large-padding-y',
      'lg:px-components-article-card-listing-spacing-large-padding-x',
    ],
    card: ['flex', 'flex-col', 'overflow-hidden', 'rounded-themes-radius-large-image', 'w-full'],
    cardBody: [
      'bg-components-generic-card-color-content-bg',
      'flex',
      'flex-col',
      'h-full',
      'p-spacing-spacing-5',
    ],
    cardCtaWrapper: [
      'flex',
      'w-full',
      '[&>*]:flex-1',
      'gap-spacing-space-between-large-2',
      'flex-wrap',
    ],
    cardDescriptionText: [
      'font-bodySans-small',
      'leading-bodySans-small',
      'line-clamp-4',
      'text-components-generic-card-color-copy',
      'mb-components-top-title-spacing-large-title-margin-bottom',
      'mt-components-generic-card-spacing-large-title-margin-bottom',
      'text-bodySans-small',
      // these 2 classes resolve the issue and can be removed
      // when leading-trim gets implemented in browser spec
      // https://caniuse.com/?search=leading-trim
      'pt-[4px]',
      'border-b-4',
      'pb-[4px]',
      'border-transparent',
      'max-h-[84px]',
      //'text-components-generic-card-color-description',
    ],
    cardHead: ['flex'],
    cardPrimaryCta: ['mr-spacing-space-between-large-2'],
    cardSubtitleText: [
      'text-header-small-xxSmall',
      'font-header-small-xxSmall',
      'leading-header-small-xxSmall',
      'line-clamp-2',
      'text-components-generic-card-color-subtitle',
      // this class resolve the issue and can be removed
      // when leading-trim gets implemented in browser spec
      // https://caniuse.com/?search=leading-trim
      'after:!mt-0',
      'sm:font-header-small-xSmall',
      'sm:leading-header-small-xSmall',
      'sm:text-header-small-xSmall',
      'pt-[4px]',
      'border-b-4',
      'pb-[4px]',
      'border-transparent',
      'max-h-[50px]',
    ],
    cardTitleText: [
      'text-header-small-xSmall',
      'font-header-large-xSmall',
      'leading-header-small-xSmall',
      'mb-components-generic-card-spacing-large-title-margin-bottom',
      'text-components-generic-card-color-title',
      'sm:font-header-large-xSmall',
      'sm:leading-header-large-xSmall',
      'sm:text-header-large-xSmall',
      'flex-1',
    ],
    cardWrapper: [
      'grid',
      'grid-cols-1',
      'gap-0',
      'w-full',
      'sm:grid-cols-2',
      'sm:gap-components-generic-card-listing-spacing-large-card-space-between',
    ],
    componentBG: ['bg-components-icon-card-listing-color-bg'],
    ctaWrapper: [],
    descriptionText: [
      'font-bodySans-small',
      'leading-bodySans-small',
      'text-bodySans-small',
      'text-components-generic-card-listing-color-copy',
      'sm:font-bodySans-small',
      'sm:leading-bodySans-medium',
      'sm:text-bodySans-medium',
      'flex-1',
    ],
    legalDisclaimerText: ['self-start', 'py-spacing-spacing-5'],
    svgDecorLeft: ['absolute', 'hidden', 'left-0', 'top-0', 'md:block'],
    svgDecorRight: ['absolute', 'hidden', 'right-0', 'top-0', 'md:block'],
    titleAndDescription: ['flex', 'flex-col', 'items-center', 'self-stretch'],
    titleAndDescriptionWrapperInner: ['flex', 'flex-col', 'mb-6', 'lg:mb-0'],
    titleAndDescriptionWrapperOuter: [
      'flex',
      'justify-between',
      'mb-components-top-title-spacing-small-body-margin-bottom',
      'w-full',
      'sm:mb-components-top-title-spacing-large-body-margin-bottom',
      'flex-col',
      'md:flex-row',
    ],
    titleText: [
      'text-header-small-large',
      'font-header-large-large',
      'font-text-header-small-large',
      'leading-header-small-large',
      'mb-components-top-title-spacing-small-title-margin-bottom',
      'text-components-generic-card-listing-color-title',
      'sm:font-text-header-large-large',
      'sm:leading-header-large-large',
      'sm:mb-components-product-card-listing-spacing-large-title-margin-bottom',
      'sm:text-header-large-large',
    ],
    wrapper: ['flex-col', 'flex', 'items-center', 'justify-center'],
  },
  variants: {
    alignCTA: {
      BottomCenter: {
        ctaWrapper: [
          'align-center',
          'flex',
          'mt-components-generic-card-listing-spacing-small-button-margin-top',
          'sm:mt-components-generic-card-listing-spacing-large-button-margin-top',
          'w-full',
          'sm:w-auto',
          '[&>a]:w-full',
          'sm:[&>a]:w-auto',
        ],
      },
      TopRight: {
        ctaWrapper: ['flex', 'items-center'],
      },
    },
    cardOrientation: {
      0: {
        cardWrapper: ['md:grid-cols-2'],
      },
      2: {
        cardWrapper: ['md:grid-cols-2'],
      },
      3: {
        cardWrapper: ['md:grid-cols-3'],
      },
      4: {
        cardWrapper: ['md:grid-cols-4'],
      },
    },
  },
});

// Add fallback component variant color
const fallbackComponentVariantColor = fallback?.componentVariants?.value;
const fallbackComponentVariantType = fallback?.componentVariants?.type;

// Generic Card Component
/**
 * Generic Card has three orientation
 * 1. If 2 has been selected from backend then the orientation will be as below
 * - 2x2, full width
 * 2. If 3 has been selected from backend then the orientation will be as below
 * - 3 column, 1 row, full width
 * 3. If 3 has been selected from backend then the orientation will be as below
 * - 4 column, 1 row, full width
 */

const GenericCard = (props: GenericCardProps): JSX.Element => {
  const componentVariants = ComponentVariants();
  const { themeName } = useTheme();

  if (!props.fields) return <></>;

  const {
    description,
    genericCards,
    primaryCTA,
    title,
    multiColorBar,
    disclaimerText,
    primaryCTAColor,
    primaryCTAType,
  } = props?.fields || {};

  const { alignCTA } = props?.params || {};

  const { componentName, dataSource } = props?.rendering || {};

  const cardCount: number = genericCards?.length || 0;

  let cardOrientation: CardOrientation = 0;

  if (cardCount >= 4) {
    cardOrientation = 4;
  } else if (cardCount === 3) {
    cardOrientation = 3;
  } else if (cardCount === 2) {
    cardOrientation = 2;
  }

  const {
    base,
    card,
    cardBody,
    cardCtaWrapper,
    cardDescriptionText,
    cardHead,
    cardSubtitleText,
    cardTitleText,
    cardWrapper,
    componentBG,
    ctaWrapper,
    descriptionText,
    legalDisclaimerText,
    svgDecorLeft,
    svgDecorRight,
    titleAndDescription,
    titleAndDescriptionWrapperInner,
    titleAndDescriptionWrapperOuter,
    titleText,
    wrapper,
  } = tailwindVariants({
    alignCTA: alignCTA as AlignCTA,
    cardOrientation,
  });

  const id = props?.params?.RenderingIdentifier;

  return (
    <>
      <div
        className={componentBG()}
        data-component="authorable/cards/genericcard"
        id={id ? id : undefined}
      >
        {multiColorBar?.value && (
          <MultiColorBorder
            borderColorOne="bg-colors-accent-2-400"
            borderColorTwo="bg-colors-brand-2-400"
            borderColorThree="bg-colors-accent-1-400"
            borderColorFour="bg-colors-brand-1-400"
          />
        )}
        <Container>
          <div
            className={base()}
            data-component="authorable/general/genericcard"
            data-testid="genericcard"
          >
            {componentVariants?.genericCardListing?.hasDecor && (
              <>
                <SVG className={svgDecorLeft()} svg={`GenericCard/Decor_Left,Brand=${themeName}`} />
                <SVG
                  className={svgDecorRight()}
                  svg={`GenericCard/Decor_Right,Brand=${themeName}`}
                />
              </>
            )}
            <div className={wrapper()}>
              <div className={titleAndDescription()}>
                {title && (
                  <div className={titleAndDescriptionWrapperOuter()}>
                    <div className={titleAndDescriptionWrapperInner()}>
                      {title?.value && <Text className={titleText()} field={title} tag="h2" />}
                      {description?.value && (
                        <Text
                          className={descriptionText()}
                          encode={false}
                          field={description}
                          tag="p"
                        />
                      )}
                    </div>
                    {alignCTA == 'TopRight' && primaryCTA?.value?.href !== '' && (
                      <div className={ctaWrapper()}>
                        <Button
                          label={primaryCTA?.value.text}
                          // The design requires an outline CTA but field name is primaryCTA,
                          // so for that we have added a fallback as an outline value,
                          // so if there is no value in sitecore field, it will take the outline value
                          type={primaryCTAType?.value || fallbackComponentVariantType}
                          color={primaryCTAColor?.value || fallbackComponentVariantColor}
                          tag="a"
                          href={primaryCTA?.value?.href}
                          gtmEvent={{
                            event: 'cta_click',
                            type: 'primary',
                            'gtm.element.dataset.gtmLinkUrl': primaryCTA?.value?.href,
                            'gtm.element.dataset.gtmLinkName': primaryCTA?.value?.text,
                            'gtm.element.dataset.gtmDatasourceId': dataSource,
                            'gtm.element.dataset.gtmComponentName': componentName,
                          }}
                        />
                      </div>
                    )}
                  </div>
                )}
                {genericCards?.length && (
                  <div className={cardWrapper()}>
                    {genericCards?.map((cardData: GenericCard, index) => {
                      const {
                        description,
                        image,
                        primaryCTA,
                        secondaryCTA,
                        subtitle,
                        title,
                        primaryCTAType,
                        primaryCTAColor,
                        secondaryCTAType,
                        secondaryCTAColor,
                      } = cardData?.fields || {};
                      const { id, name } = cardData;

                      return (
                        <div className={card()} key={index}>
                          <div className={cardHead()}>
                            {image && <ImageWrapper field={image} layout="intrinsic" />}
                          </div>
                          <div className={cardBody()}>
                            {title?.value && (
                              <Text
                                className={cardTitleText()}
                                encode={false}
                                field={title}
                                tag="p"
                              />
                            )}
                            {subtitle?.value && (
                              <Text
                                className={cardSubtitleText()}
                                encode={false}
                                field={subtitle}
                                tag="p"
                              />
                            )}
                            {description?.value && (
                              <RichTextA11yWrapper
                                className={cardDescriptionText()}
                                field={description}
                              />
                            )}
                            <div className={cardCtaWrapper()}>
                              {primaryCTA?.value?.text && (
                                <Button
                                  //As the design requires a filled CTA, we do not need to add a fallback since
                                  //the button component has a default variant that displays a filled CTA.
                                  type={primaryCTAType?.value as string}
                                  color={primaryCTAColor?.value}
                                  label={primaryCTA?.value?.text}
                                  tag="a"
                                  href={primaryCTA?.value?.href}
                                  gtmEvent={{
                                    event: 'cta_click',
                                    type: 'primary',
                                    'gtm.element.dataset.gtmLinkUrl': primaryCTA?.value?.href,
                                    'gtm.element.dataset.gtmLinkName': primaryCTA?.value?.text,
                                    'gtm.element.dataset.gtmDatasourceId': id,
                                    'gtm.element.dataset.gtmComponentName': name,
                                  }}
                                />
                              )}
                              {secondaryCTA?.value.text && (
                                <Button
                                  //The design requires an outline CTA, so for that we have added a fallback as an outline value,
                                  //so if there is no value in sitecore field, it will take the outline value.
                                  type={secondaryCTAType?.value || fallbackComponentVariantType}
                                  color={secondaryCTAColor?.value || fallbackComponentVariantColor}
                                  label={secondaryCTA?.value?.text}
                                  tag="a"
                                  href={secondaryCTA?.value?.href}
                                  gtmEvent={{
                                    event: 'cta_click',
                                    type: 'secondary',
                                    'gtm.element.dataset.gtmLinkUrl': secondaryCTA?.value?.href,
                                    'gtm.element.dataset.gtmLinkName': secondaryCTA?.value?.text,
                                    'gtm.element.dataset.gtmDatasourceId': id,
                                    'gtm.element.dataset.gtmComponentName': name,
                                  }}
                                />
                              )}
                            </div>
                          </div>
                        </div>
                      );
                    })}
                  </div>
                )}
                {alignCTA == 'BottomCenter' && primaryCTA?.value?.href !== '' && (
                  <div className={ctaWrapper()}>
                    <Button
                      // The design requires an outline CTA but field name is primaryCTA,
                      // so for that we have added a fallback as an outline value,
                      // so if there is no value in sitecore field, it will take the outline value
                      type={primaryCTAType?.value || fallbackComponentVariantType}
                      color={primaryCTAColor?.value || fallbackComponentVariantColor}
                      label={primaryCTA?.value.text}
                      href={primaryCTA?.value?.href}
                      tag="a"
                      gtmEvent={{
                        event: 'cta_click',
                        type: 'primary',
                        'gtm.element.dataset.gtmLinkUrl': primaryCTA?.value?.href,
                        'gtm.element.dataset.gtmLinkName': primaryCTA?.value?.text,
                        'gtm.element.dataset.gtmDatasourceId': dataSource,
                        'gtm.element.dataset.gtmComponentName': componentName,
                      }}
                    />
                  </div>
                )}
              </div>
            </div>
            {disclaimerText?.value != '' && (
              <LegalDisclaimer
                disclaimerText={disclaimerText}
                disclaimerClasses={legalDisclaimerText()}
              />
            )}
          </div>
        </Container>
        {multiColorBar?.value && (
          <MultiColorBorder
            borderColorOne="bg-colors-accent-2-400"
            borderColorTwo="bg-colors-brand-2-400"
            borderColorThree="bg-colors-accent-1-400"
            borderColorFour="bg-colors-brand-1-400"
          />
        )}
      </div>
    </>
  );
};

export default GenericCard;
