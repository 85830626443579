/**
 * @typedef {import("./src/lib/graphql-data/domains-query").SiteDomainLocale} SiteDomainLocale
 */

const jssConfig = require('./src/temp/config');
const plugins = require('./src/temp/next-config-plugins') || {};

const publicUrl = jssConfig.publicUrl;

/** @type SiteDomainLocale[] */
const siteDomains = jssConfig?.siteDomains ? JSON.parse(jssConfig?.siteDomains) : [];

/* Start: Domains data to be inserted inside the i18n section - domains */
let previewDomainValue = false;
let domainsData = '';

//console.log('---------------CORS---------------', corsDomains);
if (!process.env.IS_PREVIEW_SITE) {
  domainsData = siteDomains.map((e) => {
    const localeFormat = e.locales
      ?.map((locale) => {
        return `"${locale}"`;
      })
      .join(',');

    return `{ "domain": "${e.domain}", "defaultLocale": "${e.defaultLocale}", "locales": [${localeFormat}]}`;
  });
} else {
  domainsData = siteDomains
    .map((e) => {
      if (!previewDomainValue) {
        previewDomainValue = true;
        return `{ "domain": "${e.previewDomain}", "defaultLocale": "${e.defaultLocale}" }`;
      }
    })
    .join('');
}
/* End: Domains data to be inserted inside the i18n section - domains */

let locales = [
  ...new Set([...siteDomains.map((x) => x.locales ?? []).flat(), jssConfig.defaultLanguage]),
];

if (process.env.I18N_DOMAINS) {
  domainsData = process.env.I18N_DOMAINS;
}
if (process.env.LOCALES) {
  locales = [
    ...new Set([
      ...process.env.LOCALES.split(',')
        .map((x) => x?.trim() ?? [])
        .flat(),
    ]),
  ];
}

console.log('next.config.js using public url: ' + publicUrl);
console.log('next.config.js using config.js locales: ' + locales);
console.log('next.config.js using config.js domains: ' + jssConfig?.siteDomains);
console.log('next.config.js using config.js domains i18n: ' + domainsData);

/**
 * @type {import('next').NextConfig}
 */
const nextConfig = {
  productionBrowserSourceMaps: true,
  // Set assetPrefix to our public URL for editing host only.  Otherwise use current domain.
  assetPrefix: process.env.IS_EDITING_HOST === 'true' ? publicUrl : undefined,

  // Allow specifying a distinct distDir when concurrently running app in a container
  distDir: process.env.NEXTJS_DIST_DIR || '.next',

  // Make the same PUBLIC_URL available as an environment variable on the client bundle
  env: {
    PUBLIC_URL: publicUrl,
    IS_PREVIEW_SITE: process.env.IS_PREVIEW_SITE,
    IS_EDITING_HOST: process.env.IS_EDITING_HOST,
  },

  i18n: {
    // Note, we are processing "default" in the middleware and rewriting it to the appropriate language.
    // It's not a real language that will be used.
    locales: ['default', ...locales.map((x) => x?.toLocaleLowerCase())],
    // Default to 'default' so we can handle the domain language resolution ourselves.
    // This is needed because the `domains` config does not all multiple domains to point to the same language.
    defaultLocale: 'default',
    // We're handling domains in middleware where we're handling the rest of the language settings
    // domains: [],
    // We don't want NextJS to automatically redirect based on browser settings.
    localeDetection: false,
    domains: JSON.parse('[' + domainsData + ']'),
  },

  // Allow Domain
  images: {
    domains: [
      'edge.sitecorecloud.io',
      'vanilla.scjdart.localhost',
      'ziploc.scjdart.localhost',
      'img.youtube.com',
    ],
    dangerouslyAllowSVG: true,
  },

  // Enable React Strict Mode
  reactStrictMode: true,

  async rewrites() {
    // When in connected mode we want to proxy Sitecore paths off to Sitecore
    return [
      // API endpoints
      {
        source: '/sitecore/api/:path*',
        destination: `${jssConfig.sitecoreApiHost}/sitecore/api/:path*`,
      },
      // media items
      {
        source: '/-/:path*',
        destination: `${jssConfig.sitecoreApiHost}/-/:path*`,
      },
      // healthz check
      {
        source: '/healthz',
        destination: '/api/healthz',
      },
      // rewrite for Sitecore service pages
      {
        source: '/sitecore/service/:path*',
        destination: `${jssConfig.sitecoreApiHost}/sitecore/service/:path*`,
      },
    ];
  },

  webpack(config) {
    // Grab the existing rule that handles SVG imports
    const fileLoaderRule = config.module.rules.find((rule) => rule.test?.test?.('.svg'));

    config.module.rules.push(
      // Reapply the existing rule, but only for svg imports ending in ?url
      {
        ...fileLoaderRule,
        test: /\.svg$/i,
        resourceQuery: /url/, // *.svg?url
      },
      // Convert all other *.svg imports to React components
      {
        test: /\.svg$/i,
        issuer: fileLoaderRule.issuer,
        resourceQuery: { not: [...fileLoaderRule.resourceQuery.not, /url/] }, // exclude if *.svg?url
        use: ['@svgr/webpack'],
      }
    );

    // Modify the file loader rule to ignore *.svg, since we have it handled now.
    fileLoaderRule.exclude = /\.svg$/i;

    return config;
  },
};

module.exports = () => {
  // Run the base config through any configured plugins
  console.log(Object.values(plugins).reduce((acc, plugin) => plugin(acc), nextConfig));
  return Object.values(plugins).reduce((acc, plugin) => plugin(acc), nextConfig);
};
