// Global
import { Text } from '@sitecore-jss/sitecore-jss-nextjs';
import React, { KeyboardEventHandler, MouseEventHandler, ReactElement, Ref } from 'react';
import { sendGTMEvent } from '@next/third-parties/google';
import { tv } from 'tailwind-variants';

// Lib
import { GtmEvent } from 'lib/utils/gtm-utils';

// Local
import GoogleMaterialSymbol from 'helpers/GoogleMaterialSymbol/GoogleMaterialSymbol';

type CompoundTypes =
  | 'filled_brand_large'
  | 'filled_brand_compressed'
  | 'filled_tonal_large'
  | 'filled_tonal_compressed'
  | 'filled_white_large'
  | 'filled_white_compressed'
  | 'outline_brand_large'
  | 'outline_brand_compressed'
  | 'outline_tonal_large'
  | 'outline_tonal_compressed'
  | 'outline_white_large'
  | 'outline_white_compressed'
  | 'text_brand_large'
  | 'text_brand_compressed'
  | 'text_tonal_large'
  | 'text_tonal_compressed'
  | 'text_white_large'
  | 'text_white_compressed'
  | undefined;

type HasIcon = 'false_compressed' | 'false_large' | 'true_compressed' | 'true_large' | undefined;

type NativeAttrs = Omit<React.ButtonHTMLAttributes<undefined>, keyof Props>;

interface Props {
  auto?: boolean;
  color?: string;
  disabled?: boolean;
  gtmEvent?: GtmEvent;
  href?: string;
  iconLeft?: string;
  iconRight?: string;
  id?: string;
  label?: string;
  loading?: boolean;
  fullWidth?: boolean;
  onClick?: MouseEventHandler;
  onKeyDown?: KeyboardEventHandler;
  ref?: Ref<HTMLButtonElement | null>;
  size?: string;
  tag?: string;
  target?: string;
  title?: string;
  srOnlyText?: string;
  type: string;
  childClass?: string;
  attributes?: { [key: string]: string };
}

export type ButtonProps = Props & NativeAttrs;

const tailwindVariants = tv({
  defaultVariants: {
    compoundType: 'filled_brand_large',
  },
  slots: {
    base: [
      'inline-flex',
      'items-center',
      'justify-center',
      'whitespace-nowrap',
      'disabled:cursor-not-allowed',
      'enabled:duration-150',
      'enabled:transition',
      'hover:ease-in',
      'focus-visible:shadow-focus',
      'focus-visible:outline-0',
    ],
    text: [
      'font-bodySans-medium-semibold',
      'inline-flex',
      'items-center',
      'justify-center',
      'relative',
      'text-bodySans-medium-semibold',
      'tracking-[0.16px]',
      'top-0',
    ],
  },
  variants: {
    compoundType: {
      filled_brand_compressed: {
        base: [
          'bg-components-button-color-filled-brand-default-bg',
          'border-components-button-color-filled-brand-default-stroke',
          'border-3',
          'fill-components-button-color-filled-brand-default',
          'gap-components-button-spacing-compressed-default-space-between',
          'min-h-10',
          'py-components-button-spacing-compressed-default-padding-y',
          'px-components-button-spacing-compressed-default-padding-x',
          'rounded-themes-radius-small-button',
          'text-components-button-color-filled-brand-default-text',
          '[&>*]:gap-components-button-spacing-compressed-default-space-between',
          '[&_svg]:fill-components-button-color-filled-brand-default-icon',
          'active:bg-components-button-color-filled-brand-pressed-bg',
          'active:border-components-button-color-filled-brand-pressed-stroke',
          'active:text-components-button-color-filled-brand-pressed-text',
          '[&_svg]:active:fill-components-button-color-filled-brand-pressed-icon',
          'disabled:bg-components-button-color-filled-brand-disabled-bg/components-button-color-filled-brand-disabled-bg',
          'disabled:border-transparent',
          'disabled:text-components-button-color-filled-brand-disabled-text/components-button-color-filled-brand-disabled-text',
          '[&_svg]:disabled:fill-components-button-color-filled-brand-disabled-icon/components-button-color-filled-brand-disabled-icon',
          'focus:bg-components-button-color-filled-brand-focus-bg',
          'focus:border-components-button-color-filled-brand-focus-stroke',
          'focus:shadow-focus',
          '[&_svg]:focus:fill-components-button-color-filled-brand-focus-icon',
          'hover:bg-components-button-color-filled-brand-hover-bg',
          'hover:border-components-button-color-filled-brand-hover-stroke',
          'hover:text-components-button-color-filled-brand-hover-text',
          '[&_svg]:hover:fill-components-button-color-filled-brand-hover-icon',
        ],
      },
      filled_brand_large: {
        base: [
          'bg-components-button-color-filled-brand-default-bg',
          'border-components-button-color-filled-brand-default-stroke',
          'border-3',
          'fill-components-button-color-filled-brand-default',
          'gap-components-button-spacing-large-default-space-between',
          'min-h-12',
          'py-components-button-spacing-large-default-padding-y',
          'px-components-button-spacing-large-default-padding-x',
          'rounded-themes-radius-large-button',
          'text-components-button-color-filled-brand-default-text',
          '[&>*]:gap-components-button-spacing-large-default-space-between',
          '[&_svg]:fill-components-button-color-filled-brand-default-icon',
          'active:bg-components-button-color-filled-brand-pressed-bg',
          'active:border-components-button-color-filled-brand-pressed-stroke',
          'disabled:bg-components-button-color-filled-brand-disabled-bg/components-button-color-filled-brand-disabled-bg',
          'disabled:border-transparent',
          'disabled:text-components-button-color-filled-brand-disabled-text/components-button-color-filled-brand-disabled-text',
          '[&_svg]:disabled:fill-components-button-color-filled-brand-disabled-icon/components-button-color-filled-brand-disabled-icon',
          'focus:bg-components-button-color-filled-brand-focus-bg',
          'focus:border-components-button-color-filled-brand-focus-stroke',
          'focus:shadow-focus',
          '[&_svg]:focus:fill-components-button-color-filled-brand-focus-icon',
          'hover:bg-components-button-color-filled-brand-hover-bg',
          'hover:border-components-button-color-filled-brand-hover-stroke',
          'hover:text-components-button-color-filled-brand-hover-text',
          '[&_svg]:hover:fill-components-button-color-filled-brand-hover-icon',
        ],
      },
      filled_tonal_compressed: {
        base: [
          'bg-components-button-color-filled-tonal-default-bg',
          'border-components-button-color-filled-tonal-default-stroke',
          'border-3',
          'fill-components-button-color-filled-tonal-default',
          'gap-components-button-spacing-compressed-default-space-between',
          'min-h-10',
          'py-components-button-spacing-compressed-default-padding-y',
          'px-components-button-spacing-compressed-default-padding-x',
          'rounded-themes-radius-small-button',
          'text-components-button-color-filled-tonal-default-text',
          '[&>*]:gap-components-button-spacing-compressed-default-space-between',
          '[&_svg]:fill-components-button-color-filled-tonal-default-icon',
          'active:bg-components-button-color-filled-tonal-pressed-bg',
          'active:border-components-button-color-filled-tonal-pressed-stroke',
          'disabled:bg-components-button-color-filled-tonal-disabled-bg/components-button-color-filled-tonal-disabled-bg',
          'disabled:border-transparent',
          'disabled:text-components-button-color-filled-tonal-disabled-text/components-button-color-filled-tonal-disabled-text',
          '[&_svg]:disabled:fill-components-button-color-filled-tonal-disabled-icon/components-button-color-filled-tonal-disabled-icon',
          'focus:bg-components-button-color-filled-tonal-focus-bg',
          'focus:border-components-button-color-filled-tonal-focus-stroke',
          'focus:shadow-focus',
          '[&_svg]:focus:fill-components-button-color-filled-tonal-focus-icon',
          'hover:bg-components-button-color-filled-tonal-hover-bg',
          'hover:border-components-button-color-filled-tonal-hover-stroke',
          'hover:text-components-button-color-filled-tonal-hover-text',
          '[&_svg]:hover:fill-components-button-color-filled-tonal-hover-icon',
        ],
      },
      filled_tonal_large: {
        base: [
          'bg-components-button-color-filled-tonal-default-bg',
          'border-components-button-color-filled-tonal-default-stroke',
          'border-3',
          'fill-components-button-color-filled-tonal-default',
          'gap-components-button-spacing-large-default-space-between',
          'min-h-12',
          'py-components-button-spacing-large-default-padding-y',
          'px-components-button-spacing-large-default-padding-x',
          'rounded-themes-radius-large-button',
          'text-components-button-color-filled-tonal-default-text',
          '[&>*]:gap-components-button-spacing-large-default-space-between',
          '[&_svg]:fill-components-button-color-filled-tonal-default-icon',
          'active:bg-components-button-color-filled-tonal-pressed-bg',
          'active:border-components-button-color-filled-tonal-pressed-stroke',
          'disabled:bg-components-button-color-filled-tonal-disabled-bg/components-button-color-filled-tonal-disabled-bg',
          'disabled:border-transparent',
          'disabled:text-components-button-color-filled-tonal-disabled-text/components-button-color-filled-tonal-disabled-text',
          '[&_svg]:disabled:fill-components-button-color-filled-tonal-disabled-icon/components-button-color-filled-tonal-disabled-icon',
          'focus:bg-components-button-color-filled-tonal-focus-bg',
          'focus:border-components-button-color-filled-tonal-focus-stroke',
          'focus:shadow-focus',
          '[&_svg]:focus:fill-components-button-color-filled-tonal-focus-icon',
          'hover:bg-components-button-color-filled-tonal-hover-bg',
          'hover:border-components-button-color-filled-tonal-hover-stroke',
          'hover:text-components-button-color-filled-tonal-hover-text',
          '[&_svg]:hover:fill-components-button-color-filled-tonal-hover-icon',
        ],
      },
      filled_white_compressed: {
        base: [
          'bg-components-button-color-filled-white-default-bg',
          'border-components-button-color-filled-white-default-stroke',
          'border-3',
          'fill-components-button-color-filled-white-default',
          'gap-components-button-spacing-compressed-default-space-between',
          'min-h-10',
          'py-components-button-spacing-compressed-default-padding-y',
          'px-components-button-spacing-compressed-default-padding-x',
          'rounded-themes-radius-small-button',
          'text-components-button-color-filled-white-default-text',
          '[&>*]:gap-components-button-spacing-compressed-default-space-between',
          '[&_svg]:fill-components-button-color-filled-white-default-icon',
          'active:bg-components-button-color-filled-white-pressed-bg',
          'active:border-components-button-color-filled-white-pressed-stroke',
          'disabled:bg-components-button-color-filled-white-disabled-bg/components-button-color-filled-white-disabled-bg',
          'disabled:border-transparent',
          'disabled:text-components-button-color-filled-white-disabled-text/components-button-color-filled-white-disabled-text',
          '[&_svg]:disabled:fill-components-button-color-filled-white-disabled-icon/components-button-color-filled-white-disabled-icon',
          'focus:bg-components-button-color-filled-white-focus-bg',
          'focus:border-components-button-color-filled-white-focus-stroke',
          'focus:shadow-focus',
          '[&_svg]:focus:fill-components-button-color-filled-white-focus-icon',
          'hover:bg-components-button-color-filled-white-hover-bg',
          'hover:border-components-button-color-filled-white-hover-stroke',
          'hover:text-components-button-color-filled-white-hover-text',
          '[&_svg]:hover:fill-components-button-color-filled-white-hover-icon',
        ],
      },
      filled_white_large: {
        base: [
          'bg-components-button-color-filled-white-default-bg',
          'border-components-button-color-filled-white-default-stroke',
          'border-3',
          'fill-components-button-color-filled-white-default',
          'gap-components-button-spacing-large-default-space-between',
          'min-h-12',
          'py-components-button-spacing-large-default-padding-y',
          'px-components-button-spacing-large-default-padding-x',
          'rounded-themes-radius-small-button',
          'rounded-themes-radius-large-button',
          'text-components-button-color-filled-white-default-text',
          '[&>*]:gap-components-button-spacing-large-default-space-between',
          '[&_svg]:fill-components-button-color-filled-white-default-icon',
          'active:bg-components-button-color-filled-white-pressed-bg',
          'active:border-components-button-color-filled-white-pressed-stroke',
          'disabled:bg-components-button-color-filled-white-disabled-bg/components-button-color-filled-white-disabled-bg',
          'disabled:border-transparent',
          'disabled:text-components-button-color-filled-white-disabled-text/components-button-color-filled-white-disabled-text',
          '[&_svg]:disabled:fill-components-button-color-filled-white-disabled-icon/components-button-color-filled-white-disabled-icon',
          'focus:bg-components-button-color-filled-white-focus-bg',
          'focus:border-components-button-color-filled-white-focus-stroke',
          'focus:shadow-focus',
          '[&_svg]:focus:fill-components-button-color-filled-white-focus-icon',
          'hover:bg-components-button-color-filled-white-hover-bg',
          'hover:border-components-button-color-filled-white-hover-stroke',
          'hover:text-components-button-color-filled-white-hover-text',
          '[&_svg]:hover:fill-components-button-color-filled-white-hover-icon',
        ],
      },
      outline_brand_compressed: {
        base: [
          'bg-transparent',
          'border-components-button-color-outline-brand-default-stroke',
          'border-3',
          'fill-components-button-color-outline-brand-default',
          'min-h-10',
          'py-components-button-spacing-compressed-default-padding-y',
          'px-components-button-spacing-compressed-default-padding-x',
          'rounded-themes-radius-small-button',
          'text-components-button-color-outline-brand-default-text',
          '[&>*]:gap-components-button-spacing-compressed-default-space-between',
          '[&_svg]:fill-components-button-color-outline-brand-default-icon',
          'active:bg-components-button-color-outline-brand-pressed-bg',
          'active:border-components-button-color-outline-brand-pressed-stroke',
          'active:text-components-button-color-outline-brand-pressed-text',
          '[&_svg]:active:fill-components-button-color-outline-brand-pressed-icon',
          'disabled:bg-transparent',
          'disabled:border-components-button-color-outline-brand-disabled-stroke/components-button-color-outline-brand-disabled-stroke',
          'disabled:text-components-button-color-outline-brand-disabled-text/components-button-color-outline-brand-disabled-text',
          '[&_svg]:disabled:fill-components-button-color-outline-brand-disabled-icon/components-button-color-outline-brand-disabled-icon',
          'focus:bg-components-button-color-outline-brand-focus-bg',
          'focus:border-components-button-color-outline-brand-focus-stroke',
          'focus:shadow-focus',
          'focus:text-components-button-color-outline-brand-focus-text',
          '[&_svg]:focus:fill-components-button-color-outline-brand-focus-icon',
          'hover:bg-components-button-color-outline-brand-hover-bg',
          'hover:border-components-button-color-outline-brand-hover-stroke',
          'hover:text-components-button-color-outline-brand-hover-text',
          '[&_svg]:hover:fill-components-button-color-outline-brand-hover-icon',
        ],
      },
      outline_brand_large: {
        base: [
          'bg-transparent',
          'border-3',
          'border-components-button-color-outline-brand-default-stroke',
          'fill-components-button-color-outline-brand-default',
          'min-h-12',
          'py-components-button-spacing-large-default-padding-y',
          'px-components-button-spacing-large-default-padding-x',
          'rounded-themes-radius-large-button',
          'text-components-button-color-outline-brand-default-text',
          '[&>*]:gap-components-button-spacing-large-default-space-between',
          '[&_svg]:fill-components-button-color-outline-brand-default-icon',
          'active:bg-components-button-color-outline-brand-pressed-bg',
          'active:border-components-button-color-outline-brand-pressed-stroke',
          'active:text-components-button-color-outline-brand-pressed-text',
          '[&_svg]:active:fill-components-button-color-outline-brand-pressed-icon',
          'disabled:bg-transparent',
          'disabled:border-components-button-color-outline-brand-disabled-stroke/components-button-color-outline-brand-disabled-stroke',
          'disabled:text-components-button-color-outline-brand-disabled-text/components-button-color-outline-brand-disabled-text',
          '[&_svg]:disabled:fill-components-button-color-outline-brand-disabled-icon/components-button-color-outline-brand-disabled-icon',
          'focus:bg-components-button-color-outline-brand-focus-bg',
          'focus:border-components-button-color-outline-brand-focus-stroke',
          'focus:shadow-focus',
          'focus:text-components-button-color-outline-brand-focus-text',
          '[&_svg]:focus:fill-components-button-color-outline-brand-focus-icon',
          'hover:bg-components-button-color-outline-brand-hover-bg',
          'hover:border-components-button-color-outline-brand-hover-stroke',
          'hover:text-components-button-color-outline-brand-hover-text',
          '[&_svg]:hover:fill-components-button-color-outline-brand-hover-icon',
        ],
      },
      outline_tonal_compressed: {
        base: [
          'bg-transparent',
          'border-components-button-color-outline-tonal-default-stroke',
          'border-3',
          'fill-components-button-color-outline-tonal-default',
          'min-h-10',
          'py-components-button-spacing-compressed-default-padding-y',
          'px-components-button-spacing-compressed-default-padding-x',
          'rounded-themes-radius-small-button',
          'text-components-button-color-outline-tonal-default-text',
          '[&>*]:gap-components-button-spacing-compressed-default-space-between',
          '[&_svg]:fill-components-button-color-outline-tonal-default-icon',
          'active:bg-components-button-color-outline-tonal-pressed-bg',
          'active:border-components-button-color-outline-tonal-pressed-stroke',
          'active:text-components-button-color-outline-tonal-pressed-text',
          '[&_svg]:active:fill-components-button-color-outline-tonal-pressed-icon',
          'disabled:bg-transparent',
          'disabled:border-components-button-color-outline-tonal-disabled-stroke/components-button-color-outline-tonal-disabled-stroke',
          'disabled:text-components-button-color-outline-tonal-disabled-text/components-button-color-outline-tonal-disabled-text',
          '[&_svg]:disabled:fill-components-button-color-outline-tonal-disabled-icon/components-button-color-outline-tonal-disabled-icon',
          'focus:bg-components-button-color-outline-tonal-focus-bg',
          'focus:border-components-button-color-outline-tonal-focus-stroke',
          'focus:shadow-focus',
          'focus:text-components-button-color-outline-tonal-focus-text',
          '[&_svg]:focus:fill-components-button-color-outline-tonal-focus-icon',
          'hover:bg-components-button-color-outline-tonal-hover-bg',
          'hover:border-components-button-color-outline-tonal-hover-stroke',
          'hover:text-components-button-color-outline-tonal-hover-text',
          '[&_svg]:hover:fill-components-button-color-outline-tonal-hover-icon',
        ],
      },
      outline_tonal_large: {
        base: [
          'bg-transparent',
          'border-3',
          'border-components-button-color-outline-tonal-default-stroke',
          'fill-components-button-color-outline-tonal-default',
          'min-h-12',
          'py-components-button-spacing-large-default-padding-y',
          'px-components-button-spacing-large-default-padding-x',
          'rounded-themes-radius-large-button',
          'text-components-button-color-outline-tonal-default-text',
          '[&>*]:gap-components-button-spacing-large-default-space-between',
          '[&_svg]:fill-components-button-color-outline-tonal-default-icon',
          'active:bg-components-button-color-outline-tonal-pressed-bg',
          'active:border-components-button-color-outline-tonal-pressed-stroke',
          'active:text-components-button-color-outline-tonal-pressed-text',
          '[&_svg]:active:fill-components-button-color-outline-tonal-pressed-icon',
          'disabled:bg-transparent',
          'disabled:border-components-button-color-outline-tonal-disabled-stroke/components-button-color-outline-tonal-disabled-stroke',
          'disabled:text-components-button-color-outline-tonal-disabled-text/components-button-color-outline-tonal-disabled-text',
          '[&_svg]:disabled:fill-components-button-color-outline-tonal-disabled-icon/components-button-color-outline-tonal-disabled-icon',
          'focus:bg-components-button-color-outline-tonal-focus-bg',
          'focus:border-components-button-color-outline-tonal-focus-stroke',
          'focus:shadow-focus',
          'focus:text-components-button-color-outline-tonal-focus-text',
          '[&_svg]:focus:fill-components-button-color-outline-tonal-focus-icon',
          'hover:bg-components-button-color-outline-tonal-hover-bg',
          'hover:border-components-button-color-outline-tonal-hover-stroke',
          'hover:text-components-button-color-outline-tonal-hover-text',
          '[&_svg]:hover:fill-components-button-color-outline-tonal-hover-icon',
        ],
      },
      outline_white_compressed: {
        base: [
          'bg-transparent',
          'border-components-button-color-outline-white-default-stroke',
          'border-3',
          'fill-components-button-color-outline-white-default',
          'min-h-10',
          'py-components-button-spacing-compressed-default-padding-y',
          'px-components-button-spacing-compressed-default-padding-x',
          'rounded-themes-radius-small-button',
          'text-components-button-color-outline-white-default-text',
          '[&>*]:gap-components-button-spacing-compressed-default-space-between',
          '[&_svg]:fill-components-button-color-outline-white-default-icon',
          'active:bg-components-button-color-outline-white-pressed-bg',
          'active:border-components-button-color-outline-white-pressed-stroke',
          'active:text-components-button-color-outline-white-pressed-text',
          '[&_svg]:active:fill-components-button-color-outline-white-pressed-icon',
          'disabled:bg-transparent',
          'disabled:border-components-button-color-outline-white-disabled-stroke/components-button-color-outline-white-disabled-stroke',
          'disabled:text-components-button-color-outline-white-disabled-text/components-button-color-outline-white-disabled-text',
          '[&_svg]:disabled:fill-components-button-color-outline-white-disabled-icon/components-button-color-outline-white-disabled-icon',
          'focus:bg-components-button-color-outline-white-focus-bg',
          'focus:border-components-button-color-outline-white-focus-stroke',
          'focus:shadow-focus',
          'focus:text-components-button-color-outline-white-focus-text',
          '[&_svg]:focus:fill-components-button-color-outline-white-focus-icon',
          'hover:bg-components-button-color-outline-white-hover-bg',
          'hover:border-components-button-color-outline-white-hover-stroke',
          'hover:text-components-button-color-outline-white-hover-text',
          '[&_svg]:hover:fill-components-button-color-outline-white-hover-icon',
        ],
      },
      outline_white_large: {
        base: [
          'bg-transparent',
          'border-components-button-color-outline-white-default-stroke',
          'border-3',
          'fill-components-button-color-outline-white-default',
          'min-h-12',
          'py-components-button-spacing-large-default-padding-y',
          'px-components-button-spacing-large-default-padding-x',
          'rounded-themes-radius-large-button',
          'text-components-button-color-outline-white-default-text',
          '[&>*]:gap-components-button-spacing-large-default-space-between',
          '[&_svg]:fill-components-button-color-outline-white-default-icon',
          'active:bg-components-button-color-outline-white-pressed-bg',
          'active:border-components-button-color-outline-white-pressed-stroke',
          'active:text-components-button-color-outline-white-pressed-text',
          '[&_svg]:active:fill-components-button-color-outline-white-pressed-icon',
          'disabled:bg-transparent',
          'disabled:border-components-button-color-outline-white-disabled-stroke/components-button-color-outline-white-disabled-stroke',
          'disabled:text-components-button-color-outline-white-disabled-text/components-button-color-outline-white-disabled-text',
          '[&_svg]:disabled:fill-components-button-color-outline-white-disabled-icon/components-button-color-outline-white-disabled-icon',
          'focus:bg-components-button-color-outline-white-focus-bg',
          'focus:border-components-button-color-outline-white-focus-stroke',
          'focus:shadow-focus',
          'focus:text-components-button-color-outline-white-focus-text',
          '[&_svg]:focus:fill-components-button-color-outline-white-focus-icon',
          'hover:bg-components-button-color-outline-white-hover-bg',
          'hover:border-components-button-color-outline-white-hover-stroke',
          'hover:text-components-button-color-outline-white-hover-text',
          '[&_svg]:hover:fill-components-button-color-outline-white-hover-icon',
        ],
      },
      text_brand_compressed: {
        base: [
          'bg-transparent',
          'decoration-2',
          'fill-components-button-color-text-brand-default',
          'min-h-10',
          'py-components-button-spacing-compressed-default-padding-y',
          'px-components-button-spacing-compressed-default-padding-x',
          'rounded-themes-radius-small-button',
          'text-components-button-color-text-brand-default-text',
          '[&>*]:gap-components-button-spacing-compressed-default-space-between',
          '[&_svg]:fill-components-button-color-text-brand-default-icon',
          'underline-offset-4',
          'active:decoration-components-button-color-text-brand-pressed-text',
          'active:text-components-button-color-text-brand-pressed-text',
          '[&_svg]:active:fill-components-button-color-text-brand-pressed-icon',
          'disabled:text-components-button-color-text-brand-disabled-text/components-button-color-text-brand-disabled-text',
          'disabled:no-underline',
          '[&_svg]:disabled:fill-components-button-color-text-brand-disabled-icon/components-button-color-text-brand-disabled-icon',
          'focus:decoration-components-button-color-text-brand-focus-outline',
          'focus:text-components-button-color-text-brand-focus-text',
          'focus:underline',
          '[&_svg]:focus:fill-components-button-color-text-brand-focus-icon',
          'hover:underline',
          'hover:text-components-button-color-text-brand-hover-text',
          '[&_svg]:hover:fill-components-button-color-text-brand-hover-icon',
        ],
      },
      text_brand_large: {
        base: [
          'bg-transparent',
          'decoration-2',
          'fill-components-button-color-text-brand-default',
          'min-h-12',
          'py-components-button-spacing-large-default-padding-y',
          'px-components-button-spacing-large-default-padding-x',
          'text-components-button-color-text-brand-default-text',
          '[&>*]:gap-components-button-spacing-large-default-space-between',
          '[&_svg]:fill-components-button-color-text-brand-default-icon',
          'underline-offset-4',
          'active:decoration-components-button-color-text-brand-pressed-text',
          'active:text-components-button-color-text-brand-pressed-text',
          '[&_svg]:active:fill-components-button-color-text-brand-pressed-icon',
          'disabled:text-components-button-color-text-brand-disabled-text/components-button-color-text-brand-disabled-text',
          'disabled:no-underline',
          '[&_svg]:disabled:fill-components-button-color-text-brand-disabled-icon/components-button-color-text-brand-disabled-icon',
          'focus:decoration-components-button-color-text-brand-focus-outline',
          'focus:text-components-button-color-text-brand-focus-text',
          'focus:underline',
          '[&_svg]:focus:fill-components-button-color-text-brand-focus-icon',
          'hover:underline',
          'hover:text-components-button-color-text-brand-hover-text',
          '[&_svg]:hover:fill-components-button-color-text-brand-hover-icon',
        ],
      },
      text_tonal_compressed: {
        base: [
          'bg-transparent',
          'decoration-2',
          'fill-components-button-color-text-tonal-default',
          'min-h-10',
          'py-components-button-spacing-compressed-default-padding-y',
          'px-components-button-spacing-compressed-default-padding-x',
          'text-components-button-color-text-tonal-default-text',
          '[&>*]:gap-components-button-spacing-compressed-default-space-between',
          '[&_svg]:fill-components-button-color-text-tonal-default-icon',
          'underline-offset-4',
          'active:decoration-components-button-color-text-tonal-pressed-text',
          'active:text-components-button-color-text-tonal-pressed-text',
          '[&_svg]:active:fill-components-button-color-text-tonal-pressed-icon',
          'disabled:text-components-button-color-text-tonal-disabled-text/components-button-color-text-tonal-disabled-text',
          'disabled:no-underline',
          '[&_svg]:disabled:fill-components-button-color-text-tonal-disabled-icon/components-button-color-text-tonal-disabled-icon',
          'focus:decoration-components-button-color-text-tonal-focus-outline',
          'focus:text-components-button-color-text-tonal-focus-text',
          '[&_svg]:focus:fill-components-button-color-text-tonal-focus-icon',
          'focus:underline',
          'hover:underline',
          'hover:text-components-button-color-text-tonal-hover-text',
          '[&_svg]:hover:fill-components-button-color-text-tonal-hover-icon',
        ],
      },
      text_tonal_large: {
        base: [
          'bg-transparent',
          'decoration-2',
          'fill-components-button-color-text-tonal-default',
          'min-h-12',
          'py-components-button-spacing-large-default-padding-y',
          'px-components-button-spacing-large-default-padding-x',
          'text-components-button-color-text-tonal-default-text',
          'underline-offset-4',
          '[&>*]:gap-components-button-spacing-large-default-space-between',
          '[&_svg]:fill-components-button-color-text-tonal-default-icon',
          'active:decoration-components-button-color-text-tonal-pressed-text',
          'active:text-components-button-color-text-tonal-pressed-text',
          '[&_svg]:active:fill-components-button-color-text-tonal-pressed-icon',
          'disabled:text-components-button-color-text-tonal-disabled-text/components-button-color-text-tonal-disabled-text',
          'disabled:no-underline',
          '[&_svg]:disabled:fill-components-button-color-text-tonal-disabled-icon/components-button-color-text-tonal-disabled-icon',
          'focus:decoration-components-button-color-text-tonal-focus-outline',
          'focus:text-components-button-color-text-tonal-focus-text',
          'focus:underline',
          '[&_svg]:focus:fill-components-button-color-text-tonal-focus-icon',
          'hover:underline',
          'hover:text-components-button-color-text-tonal-hover-text',
          '[&_svg]:hover:fill-components-button-color-text-tonal-hover-icon',
        ],
      },
      text_white_compressed: {
        base: [
          'bg-transparent',
          'decoration-2',
          'fill-components-button-color-text-white-default',
          'min-h-10',
          'py-components-button-spacing-compressed-default-padding-y',
          'px-components-button-spacing-compressed-default-padding-x',
          'text-components-button-color-text-white-default-text',
          'underline-offset-4',
          '[&>*]:gap-components-button-spacing-compressed-default-space-between',
          '[&_svg]:fill-components-button-color-text-white-default-icon',
          'active:decoration-components-button-color-text-white-pressed-text',
          'active:text-components-button-color-text-white-pressed-text',
          '[&_svg]:active:fill-components-button-color-text-white-pressed-icon',
          'disabled:text-components-button-color-text-white-disabled-text/components-button-color-text-white-disabled-text',
          'disabled:no-underline',
          '[&_svg]:disabled:fill-components-button-color-text-white-disabled-icon/components-button-color-text-white-disabled-icon',
          'focus:decoration-components-button-color-text-white-focus-outline',
          'focus:text-components-button-color-text-white-focus-text',
          'focus:underline',
          '[&_svg]:focus:fill-components-button-color-text-white-focus-icon',
          'hover:underline',
          'hover:text-components-button-color-text-white-hover-text',
          '[&_svg]:hover:fill-components-button-color-text-white-hover-icon',
        ],
      },
      text_white_large: {
        base: [
          'bg-transparent',
          'decoration-2',
          'fill-components-button-color-text-white-default',
          'min-h-12',
          'py-components-button-spacing-large-default-padding-y',
          'px-components-button-spacing-large-default-padding-x',
          'text-components-button-color-text-white-default-text',
          'underline-offset-4',
          '[&>*]:gap-components-button-spacing-large-default-space-between',
          '[&_svg]:fill-components-button-color-text-white-default-icon',
          'active:decoration-components-button-color-text-white-pressed-text',
          'active:text-components-button-color-text-white-pressed-text',
          '[&_svg]:active:fill-components-button-color-text-white-pressed-icon',
          'disabled:text-components-button-color-text-white-disabled-text/components-button-color-text-white-disabled-text',
          'disabled:no-underline',
          '[&_svg]:disabled:fill-components-button-color-text-white-disabled-icon/components-button-color-text-white-disabled-icon',
          'focus:decoration-components-button-color-text-white-focus-outline',
          'focus:text-components-button-color-text-white-focus-text',
          'focus:underline',
          '[&_svg]:focus:fill-components-button-color-text-white-focus-icon',
          'hover:underline',
          'hover:text-components-button-color-text-white-hover-text',
          '[&_svg]:hover:fill-components-button-color-text-white-hover-icon',
        ],
      },
    },
    hasIcon: {
      false_compressed: [
        'py-components-button-spacing-compressed-default-padding-y',
        'px-components-button-spacing-compressed-default-padding-x',
      ],
      false_large: [
        'py-components-button-spacing-large-default-padding-y',
        'px-components-button-spacing-large-default-padding-x',
      ],
      true_compressed: [
        'pl-components-button-spacing-compressed-icon-left-padding-left',
        'pr-components-button-spacing-compressed-default-padding-x',
        'py-components-button-spacing-compressed-default-padding-y',
      ],
      true_large: [
        'pl-components-button-spacing-large-icon-left-padding-left',
        'pr-components-button-spacing-large-default-padding-x',
        'py-components-button-spacing-large-default-padding-y',
      ],
    },
    minWidth: {
      true: ['min-w-[102px]'],
    },
    isFullWidth: {
      true: { base: ['w-full'] },
    },
  },
});

const Button = ({
  attributes,
  auto = true,
  childClass,
  color = 'brand',
  disabled = false,
  fullWidth = false,
  gtmEvent,
  href,
  iconLeft,
  iconRight,
  id,
  label,
  loading = false,
  onClick = (): void => undefined,
  onKeyDown = (): void => undefined,
  ref,
  size = 'large',
  srOnlyText,
  tag = 'button',
  target,
  title,
  type = 'filled',
}: ButtonProps): ReactElement => {
  const compoundType = `${type}_${color}_${size}`;

  const compoundHasIcon =
    `
    ${
      label?.length &&
      ((iconLeft?.length && iconLeft.length > 0) || (iconRight?.length && iconRight.length > 0))
    }_${size}` || undefined;

  const { base, text } = tailwindVariants({
    compoundType: compoundType as CompoundTypes,
    hasIcon: compoundHasIcon as HasIcon,
    minWidth: !auto,
    isFullWidth: fullWidth,
  });

  const children = (
    <>
      {srOnlyText && <span className="sr-only">{srOnlyText}</span>}
      <div className={text()} data-component="helpers/general/button">
        {iconLeft && <GoogleMaterialSymbol icon={iconLeft} />}
        {loading && <GoogleMaterialSymbol className="animate-spin" icon="autorenew" />}
        {label && !loading && <Text encode={false} field={{ value: label }} tag="span" />}
        {iconRight && <GoogleMaterialSymbol icon={iconRight} />}
      </div>
    </>
  );

  return React.createElement(
    tag,
    {
      'aria-label': title ? title : label,
      className: `${base()}${childClass ? ` ${childClass}` : ''}`,
      disabled,
      href: href !== '' || href !== undefined ? href?.toLocaleLowerCase() : undefined,
      id,
      onClick: (evt: React.MouseEvent<Element, MouseEvent>) => {
        onClick(evt);
        if (gtmEvent) {
          sendGTMEvent(gtmEvent);
        }
      },
      onKeyDown: (evt: React.KeyboardEvent<Element>) => {
        onKeyDown(evt);
      },
      ref,
      target: target || null,
      title,
      ...attributes,
    },

    children
  );
};

export default Button;
