// Global
import { sendGTMEvent } from '@next/third-parties/google';
import { Text } from '@sitecore-jss/sitecore-jss-nextjs';
import { useI18n } from 'next-localization';
import { useEffect, useState } from 'react';
import { tv } from 'tailwind-variants';

// Lib
import { ComponentProps } from 'lib/component-props';
import { DartComponents } from 'lib/templates/Feature.Dart.model';

// Local
import Button from 'helpers/Button/Button';

export type BackToTopProps = ComponentProps & DartComponents.RenderingParameters.BackToTop;

const tailwindVariants = tv({
  slots: {
    base: [
      'bottom-4',
      'fixed',
      'flex',
      'gap-4',
      'group',
      'items-center',
      'space-between',
      '-order-1',
      'z-40',
      'hover:cursor-pointer',
      'md:bottom-6',
    ],
    button: [
      'bg-components-button-color-filled-brand-default-bg',
      'hover:!bg-components-button-color-filled-brand-default-bg',
      'md:hover:!bg-components-button-color-filled-brand-hover-bg',
      'border-2',
      'border-components-fab-color-border-default',
      'h-[40px]',
      'min-h-[initial]',
      '!px-components-button-spacing-compressed-default-padding-y',
      '!py-components-button-spacing-compressed-default-padding-y',
      'rounded-components-fab-spacing-border-radius',
      'w-[40px]',
      'hover:border-components-fab-color-border-default',
      'md:h-[48px]',
      'md:px-components-button-spacing-large-icon-only-padding-x',
      'md:py-components-button-spacing-large-icon-only-padding-y',
      'md:w-[48px]',
    ],
    label: [
      'hidden',
      'bg-light-blue-200',
      'bg-themes-background-color-dark',
      'duration-300',
      'md:flex',
      'font-bodySans-small-semibold',
      'items-center',
      'justify-center',
      'leading-bodySans-small-semibold',
      'min-h-[35px]',
      'mt-0',
      'order-first',
      'overflow-hidden',
      'py-spacing-padding-large-1',
      'rounded',
      'text-bodySans-small-semibold',
      'text-themes-stroke-color-light',
      'transition-width',
      'w-0',
      'whitespace-nowrap',
      'group-hover:px-spacing-padding-large-1',
      'group-hover:w-auto',
      'md:mt-2',
    ],
  },
  variants: {
    alignment: {
      left: {
        base: ['left-4', 'md:left-6'],
      },
      right: {
        base: ['right-4', 'md:right-6'],
      },
    },
    showBackToTopButton: {
      false: {
        base: ['hidden'],
      },
      true: {
        base: ['block'],
      },
    },
  },
});

const BackToTop = (props: BackToTopProps) => {
  const { enableRTL } = props?.params || {};
  const { componentName, dataSource } = props?.rendering || {};

  const i18n = useI18n();

  const [showBackToTopButton, setShowBackToTopButton] = useState(false);

  const alignment = enableRTL === '1' ? 'left' : 'right';
  const backToTopMsg = i18n.t('BacktoTop');

  const { base, button, label } = tailwindVariants({
    alignment,
    showBackToTopButton,
  });

  useEffect(() => {
    window.addEventListener('scroll', () => {
      if (window.scrollY > 100) {
        setShowBackToTopButton(true);
      } else {
        setShowBackToTopButton(false);
      }
    });

    // For Storybook only, force the button to always be shown.
    if (process.env.IS_STORYBOOK) setShowBackToTopButton(true);
  }, []);

  const handleOnClick = (e?: React.MouseEvent<HTMLElement> | React.KeyboardEvent<HTMLElement>) => {
    if (e && e.preventDefault) {
      e.preventDefault();
    }

    sendGTMEvent({
      event: 'link',
      type: 'floating button',
      'gtm.element.dataset.gtmLinkUrl': '',
      'gtm.element.dataset.gtmLinkName': 'Back to top',
      'gtm.element.dataset.gtmDatasourceId': dataSource,
      'gtm.element.dataset.gtmComponentName': componentName,
    });

    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  };

  return (
    <div
      className={base()}
      data-component="helpers/general/backtotop"
      onClick={(e) => handleOnClick(e)}
    >
      <Button
        childClass={button()}
        href="#back-to-top"
        iconLeft="expand_less"
        srOnlyText={backToTopMsg}
        tag="a"
        type="filled"
      />
      <Text className={label()} encode={false} field={{ value: backToTopMsg }} tag="span" />
    </div>
  );
};

export default BackToTop;
