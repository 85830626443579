// Global
import { convertHtmlToReact } from '@hedgedoc/html-to-react';
import React, { ReactElement, useEffect, useState } from 'react';

// Lib
import { getStaticAssetUrl } from 'lib/utils/public-url-utils';

export type Variant = 'outlined' | 'rounded' | 'sharp';

export type GoogleMaterialSymbolProps = {
  className?: string;
  fill?: boolean;
  icon: string;
  variant?: Variant;
  'aria-hidden'?: boolean;
};

const GoogleMaterialSymbol = ({
  className,
  fill,
  icon,
  variant = 'sharp' as Variant,
  ...props
}: GoogleMaterialSymbolProps): ReactElement => {
  const [fetchedIcon, setFetchedIcon] = useState('');

  useEffect(() => {
    if (!icon || !variant) return;

    async function fetchIcon() {
      const iconUri = `${getStaticAssetUrl()}/assets/google-material-symbols/${variant}/${icon}${
        fill ? '-fill' : ''
      }.svg`;

      fetch(iconUri)
        .then((res) => (res.ok ? res.text() : null))
        .then((text) => (text ? setFetchedIcon(text) : null))
        .catch((error) => {
          console.error('There was a problem with the Fetch operation:', error);
        });
    }

    fetchIcon();
  }, [icon, fill, variant]);
  return (
    <React.Fragment>
      <span className={className} data-component="helpers/general/googlematerialsymbol" {...props}>
        {convertHtmlToReact(fetchedIcon)}
      </span>
    </React.Fragment>
  );
};

export default GoogleMaterialSymbol;
