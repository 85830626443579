// Global
import { Text } from '@sitecore-jss/sitecore-jss-nextjs';
import React, { useEffect, useState } from 'react';

// Lib
import { ComponentProps } from 'lib/component-props';
import { CardComponents } from 'lib/templates/Feature.Dart.model';
import { ItemEx } from 'lib/templates/_.Sitecore.Override';

// Local
import Container from 'components/authorable/Layout/DartContainer/DartContainer';
import Button from 'helpers/Button/Button';
import ImageWrapper from 'helpers/ImageWrapper/ImageWrapper';
import fallback from 'lib/fallback/fallback';
import LinkWrapper from 'helpers/LinkWrapper/LinkWrapper';

export type BrandCard = ItemEx & CardComponents.BrandCard.BrandCardItem & NumOfCards;

interface NumOfCards {
  numOfCards: number | undefined;
}

export type BrandCardProps = ComponentProps & CardComponents.BrandCard.BrandCardsList;

export type BrandCategoryType = ItemEx &
  CardComponents.BrandCard.BrandCardCategoryItem &
  NumOfCards;

// Add fallback component variant color
const fallbackComponentVariantColor = fallback?.componentVariants?.value;
const fallbackComponentVariantType = fallback?.componentVariants?.type;

const BrandSingleCard = (props: BrandCard): JSX.Element => {
  const { brandName, brandDescription, brandImage, brandLink } = props?.fields || {};

  return (
    <React.Fragment>
      <div data-component="authorable/cards/brandcard">
        <div>
          {brandImage?.value?.src && brandLink?.value?.href && (
            <div>
              <LinkWrapper field={brandLink} suppressLinkText ignoreEE>
                <div>
                  <ImageWrapper field={brandImage} layout="intrinsic" />
                </div>
              </LinkWrapper>
              {brandName?.value && <Text encode={false} field={brandName} tag="p" />}
              {brandDescription?.value && <Text encode={false} field={brandDescription} tag="p" />}
            </div>
          )}
        </div>
      </div>
    </React.Fragment>
  );
};

const BrandCategory = (props: BrandCategoryType): JSX.Element => {
  const { title, brands } = props?.fields || {};
  return (
    <React.Fragment>
      <div data-component="authorable/cards/brandcard">
        {title?.value && <Text encode={false} field={title} tag="p" />}
        {brands?.map((item: BrandCard, key) => {
          return (
            <React.Fragment key={key}>
              <div>
                <BrandSingleCard {...item} numOfCards={brands?.length} />
              </div>
            </React.Fragment>
          );
        })}
      </div>
    </React.Fragment>
  );
};

const BrandCard = (props: BrandCardProps): JSX.Element => {
  const { componentName, dataSource } = props?.rendering || {};
  const {
    brandCategories,
    allBrandsText,
    description,
    primaryCTA,
    title,
    primaryCTAColor,
    primaryCTAType,
  } = props?.fields || {};

  const isBrowser = typeof window !== 'undefined';

  const [breakpoints, setBreakpoints] = useState('xs');
  const [windowSize, setWindowSize] = useState({
    width: isBrowser ? window.innerWidth : 0,
    height: isBrowser ? window.innerHeight : 0,
  });

  useEffect(() => {
    if (!isBrowser) {
      return; // Exit if it's not running in the browser
    }

    // Handle window resize
    const handleResize = () => {
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    };

    // Listen Event
    window.addEventListener('resize', handleResize);

    // Initial check
    handleResize();

    if (isBrowser) {
      if (windowSize.width >= 1440) {
        setBreakpoints('lg');
      } else if (windowSize.width >= 996) {
        setBreakpoints('md');
      } else if (windowSize.width >= 320) {
        setBreakpoints('sm');
      }
    }

    // Cleanup
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [isBrowser, breakpoints, windowSize.width]); // Include isBrowser in the dependency array

  if (!props.fields) return <></>;

  return (
    <div>
      <Container>
        <div data-component="authorable/general/brandcard" data-testid="brandcard">
          <div>
            {title?.value && primaryCTA?.value?.href && (
              <div>
                <div>
                  <div>
                    {title?.value && <Text encode={false} field={title} tag="p" />}
                    {description?.value && <Text encode={false} field={description} tag="p" />}
                  </div>
                  {primaryCTA?.value && (
                    <div>
                      <Button
                        href={primaryCTA?.value?.href}
                        label={primaryCTA?.value.text}
                        tag="a"
                        // The design requires an outline CTA but field name is primaryCTA,
                        // so for that we have added a fallback as an outline value,
                        // so if there is no value in sitecore field, it will take the outline value.
                        type={primaryCTAType?.value || fallbackComponentVariantType}
                        color={primaryCTAColor?.value || fallbackComponentVariantColor}
                        gtmEvent={{
                          event: 'cta_click',
                          type: 'primary',
                          'gtm.element.dataset.gtmLinkUrl': primaryCTA?.value?.href,
                          'gtm.element.dataset.gtmLinkName': primaryCTA?.value?.text,
                          'gtm.element.dataset.gtmDatasourceId': dataSource,
                          'gtm.element.dataset.gtmComponentName': componentName,
                        }}
                      />
                    </div>
                  )}
                </div>
              </div>
            )}
            <div>
              <div>
                <div>
                  {allBrandsText?.value && <Text encode={false} field={allBrandsText} tag="p" />}
                </div>
                {brandCategories?.map((item: BrandCategoryType, key) => {
                  return (
                    <React.Fragment key={key}>
                      <div>
                        <BrandCategory {...item} numOfCards={brandCategories?.length} />
                      </div>
                    </React.Fragment>
                  );
                })}
              </div>
            </div>
          </div>
        </div>
      </Container>
    </div>
  );
};

export default BrandCard;
