// Global
import { Text } from '@sitecore-jss/sitecore-jss-nextjs';
import React from 'react';
import { tv } from 'tailwind-variants';

// Lib
import { ComponentProps } from 'lib/component-props';
import { ALL_THEMES, useTheme } from 'lib/context/ThemeContext';
import { ItemEx } from 'lib/templates/_.Sitecore.Override';
import { CompositeComponents } from 'lib/templates/Feature.Dart.model';
import { Common } from 'lib/templates/Foundation.Dart.model';

// Local
import GoogleMaterialSymbol from 'helpers/GoogleMaterialSymbol/GoogleMaterialSymbol';
import RichTextA11yWrapper from 'helpers/RichTextA11yWrapper/RichTextA11yWrapper';
import SVG from 'helpers/SVG/SVG';

export type PullQuote = ItemEx &
  CompositeComponents.PullQuote.PullQuoteSlide &
  Common.Enums.dartEnum;

export type PullQuoteProps = ComponentProps & CompositeComponents.PullQuote.PullQuoteSlideList;

export type StarRating = ItemEx & Common.Enums.dartEnum;

export type PullQuoteItemData = {
  data?: PullQuote;
  renderingParams?: PullQuoteProps;
};

type Alignment = 'Center' | 'Left' | undefined;

type FontSize = 'Large' | 'Small' | undefined;

const themeVariants = ALL_THEMES.reduce(
  (acc, curr) => ((acc[curr] = {}), acc),
  {} as Record<string, object>
);

interface RatingProps {
  rating: string;
  alignment?: string;
}

const tailwindVariants = tv({
  slots: {
    pullQuoteItem: [
      'bg-components-pull-quote-color-quote-bg',
      'py-components-pull-quote-spacing-small-padding-quote-y',
      'px-components-pull-quote-spacing-small-padding-quote-x',
      'md:py-components-pull-quote-spacing-large-padding-quote-y',
      'md:px-components-pull-quote-spacing-large-padding-quote-x',
      'rounded-themes-radius-large-card',
      'h-full',
      'flex',
      'flex-col',
      'justify-center',
    ],
    pullQuoteDetails: [],
    pullQuoteDescription: [
      'font-header-large-small',
      'my-components-pull-quote-spacing-small-title-margin-bottom',
      'md:my-components-pull-quote-spacing-large-title-margin-bottom',
      'text-components-pull-quote-color-quote-title',
    ],
    pullQuoteAuthorInformation: ['flex', 'flex-row', 'gap-4', 'items-center'],
    pullQuoteAuthor: [
      'font-bodySans-medium-semibold',
      'md:text-bodySans-medium-semibold',
      'text-components-pull-quote-color-quote-by-text',
      'md:leading-bodySans-medium-semibold',
      'text-bodySans-small-semibold leading-bodySans-small-semibold',
    ],
    pullQuoteAuthorTitle: [
      'text-components-pull-quote-color-quote-detail',
      'font-bodySans-medium',
      'md:text-bodySans-medium',
      'md:text-bodySans-medium',
      'md:leading-bodySans-medium',
      'text-bodySans-small',
      'text-bodySans-small',
      'leading-bodySans-small',
    ],
    pullQuoteAuthorTitleWrapper: [
      'relative',
      "before:content:''",
      'before:h-full',
      'before:w-[1px]',
      'before:absolute',
      'before:bg-colors-grayscale-300',
      'before:left-[-8px]',
    ],
    pullQuoteQuotationMark: ['fill-components-pull-quote-color-quote-highlight', 'block'],
  },
  variants: {
    alignment: {
      Center: {
        pullQuoteItem: ['items-center'],
        pullQuoteDetails: ['items-center', 'text-center'],
        pullQuoteAuthorInformation: ['justify-center'],
      },
      Left: {},
    },
    fontSize: {
      Large: {
        pullQuoteDescription: [
          'leading-header-small-small',
          'text-header-small-small',
          'md:leading-header-large-small',
          'md:text-header-large-small',
        ],
      },
      Small: {
        pullQuoteDescription: [
          'leading-header-small-large',
          'text-header-small-large',
          'md:leading-header-large-large',
          'md:text-header-large-large',
        ],
      },
    },
    brand: {
      ...themeVariants,
      Off: {
        pullQuoteItem: [
          'border-[1px]',
          'border-solid',
          'border-components-pull-quote-color-stroke',
          'bg-auto',
          'bg-no-repeat',
          'bg-[url("/assets/Pull-Quote/Bg=Right,Breakpoint=Small,Brand=Off.svg")]',
          'bg-right-top',
          'md:bg-[url("/assets/Pull-Quote/Bg=Right,Breakpoint=Large,Brand=Off.svg")]',
          'md:bg-right-bottom',
        ],
      },
      Autan: {
        pullQuoteItem: [
          'border-[1px]',
          'border-solid',
          'border-components-pull-quote-color-stroke',
          'bg-auto',
          'bg-no-repeat',
          'bg-[url("/assets/Pull-Quote/Bg=Right,Breakpoint=Small,Brand=Autan.svg")]',
          'bg-right-top',
          'md:bg-[url("/assets/Pull-Quote/Bg=Right,Breakpoint=Large,Brand=Autan.svg")]',
          'md:bg-right-bottom',
        ],
      },
      AutanDefense: {
        pullQuoteItem: [
          'border-[1px]',
          'border-solid',
          'border-components-pull-quote-color-stroke',
          'bg-cover',
          'bg-no-repeat',
          'bg-[url("/assets/Pull-Quote/Bg=Right,Breakpoint=Small,Brand=AutanDefense.svg")]',
          'bg-right-top',
          'sm:bg-[url("/assets/Pull-Quote/Bg=Right,Breakpoint=Large,Brand=AutanDefense.svg")]',
          'md:bg-right-bottom',
        ],
      },
    },
  },
});

/* Below component takes care of rating feature */
export const Rating: React.FC<RatingProps> = ({ rating, alignment }) => {
  const itemRating = parseFloat(rating);
  const normalizedRating = Math.min(5, Math.max(0, itemRating));

  const tailwindVariantsStar = tv({
    slots: {
      base: [
        'rating',
        'flex',
        'flex-row',
        'mb-components-pull-quote-spacing-large-title-margin-bottom',
      ],
      starIcon: ['fill-components-product-card-color-star-rating'],
    },
    variants: {
      alignment: {
        Center: {
          base: ['justify-center'],
        },
        Left: {},
      },
    },
  });

  const { base } = tailwindVariantsStar({ alignment: alignment as Alignment });

  const renderStars = () => {
    const { starIcon } = tailwindVariantsStar();

    const stars = [];

    for (let i = 1; i <= 5; i++) {
      let starType = 'star';
      let fill = false;

      if (i <= Math.floor(normalizedRating)) {
        starType = 'star';
        fill = true;
      } else if (i === Math.ceil(normalizedRating) && normalizedRating % 1 !== 0) {
        starType = 'star_half';
        fill = true;
      }

      stars.push(
        <GoogleMaterialSymbol
          key={i}
          icon={starType}
          fill={fill}
          className={starIcon()}
        ></GoogleMaterialSymbol>
      );
    }

    return stars;
  };

  return <div className={base()}>{renderStars()}</div>;
};

const PullQuoteItem = ({ data, renderingParams }: PullQuoteItemData): JSX.Element => {
  const { author, authorTitle, quoteDescription, starRating } = data?.fields || {};
  const { enablePattern } = renderingParams?.fields || {};
  const { alignContent } = renderingParams?.params || {};
  const characterLimit = 70; // Need to update this, Field was not added in sitecore while working on this so once field gets in place and deployed, i will change this to dynamic.
  const star = starRating as StarRating;
  const quoteDescriptionlength = quoteDescription?.value as string;

  const alignment = alignContent === 'Left' ? 'Left' : 'Center';
  const fontSize = quoteDescriptionlength.length > characterLimit ? 'Large' : 'Small';
  const { themeName } = useTheme();
  const {
    pullQuoteAuthor,
    pullQuoteAuthorInformation,
    pullQuoteAuthorTitle,
    pullQuoteAuthorTitleWrapper,
    pullQuoteDescription,
    pullQuoteDetails,
    pullQuoteItem,
    pullQuoteQuotationMark,
  } = tailwindVariants({
    alignment: alignment as Alignment,
    fontSize: fontSize as FontSize,
    /* eslint-disable  @typescript-eslint/ban-ts-comment */
    // @ts-ignore
    brand: themeName as string,
  });
  return (
    <blockquote>
      <div className={pullQuoteItem()}>
        {enablePattern?.value === true && (
          <SVG
            className={pullQuoteQuotationMark()}
            svg={`Pull-Quote/icon,Brand=${themeName}`}
          ></SVG>
        )}
        <div className={pullQuoteDetails()}>
          <RichTextA11yWrapper className={pullQuoteDescription()} field={quoteDescription} />
          {star?.fields?.value?.value && (
            <Rating alignment={alignContent} rating={star?.fields?.value?.value} />
          )}
          <div className={pullQuoteAuthorInformation()}>
            <Text className={pullQuoteAuthor()} encode={false} field={author} tag="p" />
            {authorTitle && (
              <div className={pullQuoteAuthorTitleWrapper()}>
                <Text
                  className={pullQuoteAuthorTitle()}
                  encode={false}
                  field={authorTitle}
                  tag="span"
                />
              </div>
            )}
          </div>
        </div>
      </div>
    </blockquote>
  );
};

export default PullQuoteItem;
