// Global
import { Field, ImageField, Text, useSitecoreContext } from '@sitecore-jss/sitecore-jss-nextjs';
import React from 'react';
import { tv } from 'tailwind-variants';

// Lib
import { ComponentProps } from 'lib/component-props';
import { ProductAndArticleComponent } from 'lib/templates/Feature.Dart.model';
import { ItemEx } from 'lib/templates/_.Sitecore.Override';

// Local
import ImageWrapper from 'helpers/ImageWrapper/ImageWrapper';
import LinkWrapper from 'helpers/LinkWrapper/LinkWrapper';
import { stripHtml } from 'lib/utils/regex';

export type ArticleRelatedProductsProps = ItemEx &
  ProductAndArticleComponent.Articles.ArticleInformation;

const tailwindVariants = tv({
  slots: {
    base: [
      'bg-components-card-search-result-color-bg',
      'border-components-card-search-result-color-divider-line',
      'flex',
      'flex-col',
      'gap-spacing-spacing-5',
    ],
    componentTitle: [
      'font-header-small-medium',
      'leading-header-small-medium',
      'text-header-small-medium',
      'sm:font-header-large-medium',
      'sm:text-header-large-medium',
      'sm:leading-header-large-medium',
      'text-components-product-card-color-title',
    ],
    contentWrapper: [
      'bg-components-card-search-result-color-bg',
      'border-b',
      'border-components-card-search-result-color-divider-line',
      'items-center',
      'grid',
      'grid-cols-2',
      'gap-components-card-search-result-spacing-small-image-margin-right',
      'sm:gap-components-card-search-result-spacing-large-image-margin-right',
      'py-components-card-search-result-spacing-small-padding-y',
      'sm:py-components-card-search-result-spacing-large-padding-y',
    ],
    textWrapper: [
      'flex',
      'flex-col',
      'gap-components-card-search-result-spacing-large-eyebrow-margin-bottom',
    ],
    headlineText: [
      'font-header-small-xSmall',
      'text-header-small-xSmall',
      'leading-header-small-xSmall',
      'sm:font-header-large-xSmall',
      'sm:text-header-large-xSmall',
      'sm:leading-header-large-xSmall',
      'text-components-product-card-color-title',
      'hover:text-themes-text-color-brand1',
      'hover:underline',
      'break-words',
    ],
    image: ['rounded-themes-radius-large-image-card', 'h-auto', 'aspect-[4/3]'],
    labelText: [
      'font-bodySans-small',
      'leading-bodySans-small',
      'text-bodySans-small',
      'text-components-card-search-result-color-body',
    ],
  },
});

const ArticleRelatedProducts = (props: ComponentProps): JSX.Element => {
  const { sitecoreContext } = useSitecoreContext();
  const articleInfo = sitecoreContext?.route?.fields
    ?.articleInformation as ArticleRelatedProductsProps;
  const { base, componentTitle, contentWrapper, headlineText, image, labelText, textWrapper } =
    tailwindVariants();

  if (!articleInfo) return <></>;

  const articleRelatedProducts = articleInfo.fields.articleRelatedProducts;
  // Unique id for component
  const id = props?.params?.RenderingIdentifier;
  return (
    <div
      className={base()}
      id={id ? id : undefined}
      tabIndex={-1}
      data-component="authorable/articlerelatedproducts"
    >
      <h2 className={componentTitle()}>
        <Text encode={false} field={articleInfo?.fields?.articleRelatedProductsHeadline} />
      </h2>
      <div>
        {articleRelatedProducts &&
          articleRelatedProducts.map((product) => {
            const { url, id } = product;
            const { name, headline, label, primaryImage } =
              product?.fields?.productInformation?.fields || {};
            return (
              <div className={contentWrapper()} key={id}>
                <div className={textWrapper()}>
                  {/* label */}
                  {label && (
                    <Text
                      className={labelText()}
                      encode={false}
                      field={label?.fields?.title as Field<string>}
                      tag="p"
                    />
                  )}
                  {/* headline / link */}
                  <LinkWrapper
                    className={headlineText()}
                    field={{
                      value: {
                        href: url,
                        title: name,
                        text: headline?.value,
                      },
                    }}
                    aria-label={stripHtml(headline?.value as string)}
                  />
                </div>
                <div>
                  {/* image */}
                  {primaryImage && (
                    <ImageWrapper className={image()} field={primaryImage as ImageField} />
                  )}
                </div>
              </div>
            );
          })}
      </div>
    </div>
  );
};

export default ArticleRelatedProducts;
