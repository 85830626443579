import { tv } from 'tailwind-variants';

const tailwindVariantProduct = tv({
  slots: {
    productListContainer: [
      'w-full',
      'md:mb-components-product-results-list-with-search-spacing-large-card-container-margin-bottom',
      'mb-components-product-results-list-with-search-spacing-small-card-margin-bottom',
    ],
    ctaWrapper: ['[&>a]:w-full'],
    cardWrapper: [
      'flex',
      'flex-col',
      'w-full',
      'bg-components-product-card-color-bg',
      'overflow-hidden',
      'h-full',
      'rounded-themes-radius-large-card',
      'border-colors-grayscale-200',
      'border',
    ],
    badgeContainer: ['absolute', 'z-10'],
    badgeBG: [
      'block',
      'text-center',
      'bg-components-product-card-color-badge-bg',
      'font-bodySans-medium-semibold',
      'leading-bodySans-medium-semibold',
      'text-bodySans-medium-semibold',
      'text-themes-text-color-white',
      'py-[8px]',
      'px-[34px]',
    ],
    cardMediaWrapper: ['flex', 'relative'],
    cardMedia: ['rounded-themes-radius-large-image', 'flex', 'aspect-card'],
    cardMediaImage: ['object-cover', 'min-h-full'],
    cardContentWrapper: [
      'py-components-product-card-spacing-small-padding-content-y',
      'px-components-product-card-spacing-small-padding-content-x',
      'md:py-components-product-card-spacing-large-padding-content-y',
      'md:py-components-product-card-spacing-large-padding-content-y',
      'flex',
      'flex-col',
      'h-full',
      'items-stretch',
    ],
    cardContainer: [
      'flex',
      'flex-col',
      'pb-components-product-card-spacing-large-copy-margin-bottom',
      'gap-components-product-card-spacing-large-category-margin-bottom',
      'flex-1', //1
    ],
    productInfoContainer: [
      'flex',
      'flex-col',
      'items-stretch',
      'flex-1',
      'h-full',
      'justify-between',
    ],
    productTitle: [
      'text-header-small-xSmall',
      'font-header-small-xSmall',
      'leading-header-small-xSmall',
      'text-components-product-card-color-title',
      'md:font-header-large-xSmall',
      'md:leading-header-large-xSmall',
      'md:text-header-large-xSmall',
      'mb-components-product-card-spacing-large-title-margin-bottom',
    ],
    labelBg: ['bg-components-product-card-color-bg'],
    rating: ['flex', 'flex-row', 'mb-components-product-card-spacing-large-title-margin-bottom'],
    starIcon: ['fill-colors-accent-1-400'],
    buttonClasses: [
      'group',
      'w-8',
      'h-8',
      'disabled:border-components-button-color-outline-white-disabled-stroke',
      'disabled:bg-transparent',
      'disabled:border-components-button-color-outline-white-disabled-stroke',
      'disabled:text-components-button-color-outline-white-disabled-text',
      'border-components-button-color-outline-white-outline-stroke',
      'border-[3px]',
      'rounded-themes-radius-large-button',
      'mr-2',
      'hover:border-components-button-color-outline-white-hover-stroke',
      'hover:bg-components-button-color-outline-white-hover-bg',
      'active:bg-components-button-color-outline-white-pressed-bg',
      'active:border-components-button-color-outline-white-pressed-stroke',
      'disabled:pointer-events-none',
    ],
    productDescription: [
      'font-bodySans-small',
      'leading-bodySans-small',
      'text-bodySans-small',
      'text-components-product-card-color-description',
      'md:leading-bodySans-medium',
      'md:font-bodySans-medium',
      'md:text-bodySans-medium',
      'line-clamp-2',
      'pt-[4px]',
      'border-b-4',
      'pb-[4px]',
      'border-transparent',
      'max-h-[50px]',
    ],
    cta: ['w-full'],
    productCardListing: [
      'w-full',
      'flex',
      'flex-wrap',
      'gap-components-article-card-listing-spacing-large-card-space-between',
      'items-stretch',
      'outline-none',
    ],
    productCard: ['md:w-[calc(theme(width.1/3)-1rem)]', 'w-full'],
    productImage: ['w-full', '[&>div]:'],
  },
  variants: {
    isBadgeFullWidth: {
      '1': { badgeBG: ['h-[39px]'], badgeContainer: ['w-full'] },
      undefined: {
        badgeBG: ['h-[30px]', 'min-w-[130px]'],
        badgeContainer: ['top-0', 'left-1/2', 'transform', '-translate-x-1/2'],
      },
    },
  },
});
export default tailwindVariantProduct;

export const tailwindVariantProductSearch = tv({
  slots: {
    base: ['flex'],
    paginationContainer: [
      'flex',
      'flex-col',
      'border-t',
      'border-solid',
      'border-components-pagination-on-white-stroke',
      'md:gap-spacing-spacing-5',
      'md:pt-spacing-spacing-7',
      'pt-spacing-spacing-5',
    ],
    paginationRoot: ['flex', 'justify-between', 'items-start'],
    paginationIcon: [
      'p-0',
      'min-h-auto',
      ['[&>button]:flex'],
      ['[&>button]:p-0'],
      ['[&>button]:min-h-min'],
      'font-bodySans-medium-semibold',
      'text-bodySans-medium-semibold',
      'leading-bodySans-medium-semibold',
      'text-components-pagination-on-white-stroke',
    ],
    paginationPageWrapper: [
      'md:flex',
      'flex-row',
      'gap-components-button-spacing-compressed-default-space-between',
      'hidden',
      '[&_span]:pb-[3px]',
      '[&_span]:text-components-pagination-on-white-text-default',
    ],
    paginationPage: [
      'flex',
      'py-components-button-spacing-compressed-default-padding-y',
      'px-components-button-spacing-compressed-default-padding-x',
      'rounded-themes-radius-large-button-icon',
      'font-bodySans-medium-semibold',
      'text-bodySans-medium-semibold',
      'leading-bodySans-medium-semibold',
      'text-components-pagination-on-white-text-default',
    ],
    paginationDisplayPage: [
      'flex-row',
      'justify-center',
      'font-bodySans-medium-semibold',
      'text-bodySans-medium-semibold',
      'leading-bodySans-medium-semibold',
      'md:font-bodySans-small',
      'md:text-bodySans-small',
      'md:leading-bodySans-small',
      'text-components-pagination-on-white-text-default',
    ],
    paginationGroup: ['flex-col', 'items-center', 'gap-spacing-spacing-5', 'flex'],
  },
  variants: {
    isMobile: {
      true: {
        paginationDisplayPage: ['md:hidden', 'flex'],
      },
      false: {
        paginationDisplayPage: ['hidden', 'md:flex'],
      },
    },
    currentPage: {
      true: {
        paginationPage: [
          'bg-components-pagination-on-white-bg-active',
          '!text-components-pagination-on-white-text-active',
        ],
      },
      false: {
        paginationPage: [],
      },
    },
  },
});

export const tailwindProductTopBar = tv({
  slots: {
    base: ['flex', 'justify-between', 'pb-spacing-spacing-4', 'flex-col', 'md:flex-row'],
    categoryWrapper: [
      'flex',
      'w-full',
      'flex-row',
      'no-scrollbar',
      'overflow-y-clip',
      'overflow-x-auto',
      'relative',
      'whitespace-nowrap',
      'max-md:mb-components-product-results-list-with-search-spacing-small-filter-space-between',
      'max-md:mb-[12px]', // Added hard-coded value as we do not have components-product-results-list-with-search-spacing-small-filter-space-between in out token system
      'snap-x',
      'snap-mandatory',
    ],
    categoryList: ['flex', 'flex-row', 'snap-x'],
    categoryContainer: [],
    categoryLabelBG: [
      'bg-components-button-group-color-button-bg-active',
      '!text-components-button-group-color-button-fg-active',
    ],
    categoryLabel: [
      'mt-[6px]',
      '!ml-0',
      'mr-components-button-spacing-large-default-padding-x',
      'hover:cursor-pointer',
    ],
    category: [
      'flex',
      'font-bodySans-medium-semibold',
      'text-bodySans-medium-semibold',
      'leading-bodySans-medium-semibold',
      'text-components-button-group-color-button-fg-default',
      'py-components-button-spacing-large-default-padding-y',
      'px-components-button-spacing-large-default-padding-x',
      'hover:cursor-pointer',
      'mt-[4px]',
    ],
    filterContainer: ['flex', 'flex-row', 'gap-[8px]', 'items-center', 'justify-between'],
    viewIcon: [],
    filterWrapper: ['relative'],
    gradientDesktop: ['absolute', 'w-[12px]', 'h-[48px]', 'left-[-12px]'],
    gradientMobile: ['absolute', 'w-[12px]', 'h-[48px]', 'right-[22px]', 'max-md:block', 'hidden'],
    categoryItem: ['snap-end', 'scroll-mx-6', 'shrink-0', 'scroll-mx-6'],
    svgWrapper: ['mb-components-top-title-spacing-large-margin-bottom'],
  },
  variants: {
    selected: {
      true: {
        viewIcon: [
          'bg-components-button-color-filled-brand-default-bg',
          'py-components-button-spacing-compressed-default-padding-y',
          'px-components-button-spacing-compressed-icon-only-padding-x',
          'rounded-themes-radius-large-button',
          'fill-color-grayscale-base-white',
        ],
      },
      false: {
        viewIcon: [
          'py-components-button-spacing-compressed-default-padding-y',
          'px-components-button-spacing-compressed-icon-only-padding-x',
          'fill-components-button-color-filled-brand-default-bg',
        ],
      },
    },
  },
});

export const tailwindProductList = tv({
  slots: {
    productCardList: ['w-full'],
    cardWrapperList: [
      'flex',
      'flex-row',
      'w-full',
      'bg-components-product-card-color-bg',
      'overflow-hidden',
      'h-full',
      'rounded-themes-radius-large-card',
      'border-colors-grayscale-200',
      'border',
    ],
    cardMediaWrapperList: [
      'flex',
      'relative',
      'md:w-1/3',
      'w-1/3',
      'items-center',
      'bg-color-grayscale-base-white',
      'max-md:items-start',
      'justify-center',
    ],
    cardContentWrapperList: [
      'py-components-product-card-spacing-small-padding-content-y',
      'px-components-product-card-spacing-small-padding-content-x',
      'md:py-components-product-card-spacing-large-padding-content-y',
      'md:py-components-product-card-spacing-large-padding-content-y',
      'flex',
      'flex-col',
      'h-full',
      'items-stretch',
      'md:w-2/3',
      'w-2/3',
    ],
    cardContainerList: [
      'flex',
      'flex-col',
      'md:pb-components-product-card-spacing-large-copy-margin-bottom',
      'pb-components-product-card-spacing-large-copy-margin-bottom',
      'gap-components-product-card-spacing-large-category-margin-bottom',
    ],
    badgeContainerList: ['absolute', 'z-10', 'md:block', 'hidden'],
    productCardListButton: ['max-md:[&>a]:w-full'],
  },
  variants: {
    isBadgeFullWidth: {
      '1': { badgeContainerList: ['w-full'] },
      undefined: {
        badgeContainerList: ['top-0', 'left-1/2', 'transform', '-translate-x-1/2'],
      },
    },
  },
});

// Tailwind Varint for Filter
export const tailwindVariants = tv({
  slots: {
    searchComponentWrapper: ['search-component-wrapper', 'bg-components-site-search-bg'],
    searchMainSection: ['search-main-section', 'w-full', 'flex'],
    searchResultsLeftWrapper: [
      'searchResults-leftWrapper',
      'bg-components-site-search-refine-bg',
      'z-[9999]',
      'overflow-auto',
      'fixed',
      'bg-white',
      'h-screen',
      'px-spacing-spacing-4',
      'top-0',
      'z-50',
      'duration-700',
      'focus:outline-none',
    ],
    searchResultsLeft: ['searchResults-left', 'flex', 'flex-col'],
    refineTitleWrapper: [
      'refineTitleWrapper',
      'flex',
      'items-center',
      'justify-between',
      'my-spacing-spacing-5',
    ],
    refineTitle: [
      'refineTitle',
      'text-components-site-search-refine-title',
      'md:font-header-large-medium',
      'md:text-header-large-medium',
      'md:leading-header-large-medium',
      'font-header-small-medium',
      'text-header-small-medium',
      'leading-header-small-medium',
    ],
    refineTitleIconWrapper: [
      'h-[24px]',
      'w-[24px]',
      'flex',
      'items-center',
      'justify-center',
      'cursor-pointer',
    ],
    refineTitleIcon: ['fill-components-button-color-filled-white-default-icon'],
    sortFilterWrapper: ['sort-option-selection-wrapper', 'relative', 'mb-spacing-spacing-5'],
    sortEyebrowText: [
      'font-bodySans-xSmall',
      'text-bodySans-xSmall',
      'text-components-dropdown-supporting-text-default',
      'leading-bodySans-xSmall',
      'block',
      'mb-[8px]',
    ],
    sortFilter: [
      'sort-filter',
      'flex',
      'h-[48px]',
      'items-center',
      'justify-between',
      'py-components-dropdown-input-padding-y',
      'pl-components-dropdown-input-padding-x',
      'font-bodySans-medium',
      'text-components-dropdown-label-text-default',
      'leading-bodySans-medium',
      'border-[1px]',
      'border-color-grayscale-cool-400',
      'rounded-themes-radius-large-form-field-input',
      'hover:border-components-dropdown-border-hover',
      'rounded',
      'w-full',
    ],
    sortFilterIconWrapper: ['h-[48px]', 'w-[48px]', 'flex', 'items-center', 'justify-center'],
    sortFilterIcon: ['fill-color-grayscale-cool-400'],
    sortFilterContentWrapper: [
      'contentWrapper',
      '[&>*]:!absolute',
      '[&>*]:!top-[85%]',
      '[&>*]:!left-0',
      '[&>*]:!w-full',
      '[&>div]:!max-h-[fit-content]',
      '[&>div]:!min-w-fit',
      '[&>div]:!h-[unset]',
      'relative',
      'z-50',
    ],
    sortFilterContent: [
      'bg-components-dropdown-bg',
      'shadow-dropdown',
      'rounded-radius-radius-1',
      'py-spacing-spacing-2',
    ],
    sortFilterOptionWrapper: [
      'flex',
      'px-components-dropdown-item-padding-x',
      'py-components-dropdown-item-padding-y',
      'font-bodySans-medium',
      'text-bodySans-medium',
      'text-components-dropdown-item-text-default',
      'leading-bodySans-medium',
      'cursor-pointer',
      'focus:border-2',
      'focus:border-components-dropdown-border-focused',
      'md:hover:bg-components-dropdown-item-bg-hover',
      'md:focus:border-none',
      '[&>*:first-child]:flex-1',
    ],
    selectionFilterWrapper: ['selection-filter-wrapper'],
    selectedFilterWrapper: [
      'selected-filter-wrapper',
      'flex',
      'gap-[8px]',
      'items-center',
      'flex-wrap',
      'mb-spacing-spacing-5',
    ],
    selectedFilter: [
      'flex',
      'items-center',
      'justify-center',
      'gap-x-components-tag-spacing-space-between',
      'bg-components-tag-color-bg',
      'py-components-tag-spacing-padding-y',
      'px-components-tag-spacing-padding-x',
      'border-[1px]',
      'border-components-tag-color-stroke',
      'rounded-themes-radius-large-tag',
      'cursor-pointer',
      'hover:ease-in',
      'hover:bg-components-tag-color-bg-hover',
      '[&_span]:hover:text-components-tag-color-text-hover',
      '[&_svg]:hover:fill-components-tag-color-text-hover',
    ],
    selectedFilterText: [
      'font-bodySans-xSmall',
      'text-bodySans-xSmall',
      'text-components-tag-color-text',
      'leading-bodySans-xSmall',
    ],
    selectedFilterIcon: [
      'fill-components-tag-color-text',
      'flex',
      'items-center',
      'justify-center',
      '[&_svg]:w-[12px]',
      '[&_svg]:h-[12px]',
    ],
    accordionFacetsWrapper: ['border-t-[1px]', 'border-components-site-search-facet-stroke'],
    accordionFacetTriggerClass: ['w-full'],
    accordionFacetsHeaderWrapper: [
      'accordion-facets-header-wrapper',
      'flex',
      'items-center',
      'justify-between',
      'py-components-site-search-facet-group-label-padding-y',
      'px-components-site-search-facet-group-label-padding-x',
    ],
    accordionFacetsHeader: [
      'font-bodySans-medium-semibold',
      'text-components-site-search-facet-group-label-text',
    ],
    accordionFacetsHeaderIconWrapper: ['h-[24px]', 'w-[24px]', 'pt-[2px]'],
    accordionFacetsHeaderIcon: ['fill-components-site-search-facet-group-label-text'],
    accordionFacetsContentWrapper: ['bg-components-dropdown-item-bg-default'],
    accordionFacetsContentItem: [
      'flex',
      'items-center',
      'py-components-site-search-filter-padding-y',
      'px-components-site-search-filter-padding-x',
    ],
    accordionFacetsContentItemIconWrapper: [
      'bg-white',
      'w-[20px]',
      'h-[20px]',
      'mr-forms-checkbox-spacing-space-between',
      'flex',
      'items-center',
      'justify-center',
      'border-[1px]',
      'border-forms-checkbox-color-stroke-default',
      'rounded-forms-checkbox-spacing-radius',
      '[&_svg]:bg-forms-checkbox-color-checkbox-selected',
      '[&_svg]:rounded-forms-checkbox-spacing-radius',
    ],
    accordionFacetsContentItemIcon: ['fill-forms-checkbox-color-checkmark-selected'],
    accordionFacetsItemLabelContainer: ['flex-1'],
    accordionFacetsItemLabelWrapper: ['flex', 'items-center', ' justify-between'],
    accordionFacetsItemLabel: [
      'font-bodySans-medium',
      'text-bodySans-medium',
      'text-components-dropdown-item-text-default',
      'leading-bodySans-medium',
    ],
    accordionContent: ['h-auto', 'overflow-auto'],
  },
  variants: {
    deviceDetect: {
      Desktop: {
        searchComponentWrapper: ['px-[80px]', 'py-[40px]'],
        searchMainSection: ['flex-row'],
        searchResultsLeftWrapper: ['w-[30%]'],
        refineTitleWrapper: ['mb-spacing-spacing-5'],
      },
      Mobile: {
        searchComponentWrapper: [
          'py-components-hero-internal-spacing-small-padding-y',
          'px-components-accordion-spacing-small-padding-x',
        ],
        searchMainSection: ['flex-col'],
        searchResultsLeftWrapper: ['w-[90%]'],
        refineTitleWrapper: ['my-spacing-spacing-3'],
      },
    },
    isFilterVisible: {
      true: {
        searchResultsLeftWrapper: ['right-0'],
      },
      false: {
        searchResultsLeftWrapper: ['-right-full'],
      },
    },
    isAccordionOpen: {
      true: {
        accordionFacetsWrapper: ['first:border-none'],
      },
      false: {
        accordionFacetsWrapper: [],
      },
    },
    isSelectedOption: {
      true: {
        sortFilterOptionWrapper: ['bg-components-dropdown-item-bg-hover'],
      },
    },
  },
});

export const tailwindMain = tv({
  slots: {
    mainContainer: [
      'md:py-components-product-results-list-with-search-spacing-large-padding-y',
      'py-components-product-results-list-with-search-spacing-small-padding-y',
      'md:px-components-product-results-list-with-search-spacing-large-padding-x',
      'px-components-product-results-list-with-search-spacing-small-padding-x',
      'relative',
      'product-card-listing',
    ],
    loaderWrapper: [
      'flex',
      'items-center',
      'h-full',
      'opacity-85',
      'absolute',
      'top-0',
      'left-0',
      'bottom-0',
      'bg-white',
      'z-50',
    ],
    searchLoaderAnimation: [
      'animate-spin',
      'block',
      'h-[80px]',
      'w-[80px]',
      'm-auto',
      'bg-components-omnibox-color-loader-icon-fill-default',
      '[clip-path:path("M73.3333_40.0038C73.3333_58.4115_58.411_73.3338_40.0033_73.3338C21.5957_73.3338_6.67334_58.4115_6.67334_40.0038C6.67334_21.5962_21.5957_6.67383_40.0033_6.67383C58.411_6.67383_73.3333_21.5962_73.3333_40.0038ZM13.3393_40.0038C13.3393_54.73_25.2772_66.6678_40.0033_66.6678C54.7295_66.6678_66.6673_54.73_66.6673_40.0038C66.6673_25.2777_54.7295_13.3398_40.0033_13.3398C25.2772_13.3398_13.3393_25.2777_13.3393_40.0038Z")]',
    ],
    filterBodyBG: [
      'fixed',
      'content-[""]',
      'h-screen',
      'w-screen',
      'top-0',
      'left-0',
      'z-30',
      'bg-colors-grayscale-700',
      'opacity-90',
      'z-[9999]',
    ],
  },
  variants: {
    isFilterVisible: {
      0: {
        loaderWrapper: ['right-0'],
      },
      1: {
        loaderWrapper: ['right-[30%]'],
      },
    },
  },
});
