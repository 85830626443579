// Global
import { sendGTMEvent } from '@next/third-parties/google';
import { Field, useSitecoreContext } from '@sitecore-jss/sitecore-jss-nextjs';
import dynamic from 'next/dynamic';
import { tv } from 'tailwind-variants';

// Lib
import { GtmEvent } from 'lib/utils/gtm-utils';
import { memo } from 'react';

export type youtubeProps = {
  field?: Field<string>;
  gtmEvent?: GtmEvent;
  mute?: boolean;
  class?: string;
  responsive?: boolean;
};

const tailwindVariants = tv({
  slots: {
    reactPlayer: ['[&_iframe]:rounded-themes-radius-large-image-card'],
  },
});

const Video = (youtubeProps: youtubeProps): JSX.Element => {
  // React Player
  const ReactPlayer = dynamic(() => import('react-player'), { ssr: false });
  const { reactPlayer } = tailwindVariants();
  const { responsive } = youtubeProps;
  // YouTube domain from context
  const { sitecoreContext } = useSitecoreContext();
  const youTubeURL = sitecoreContext?.youTubeURL?.youTubeURL as string;

  // YouTubeURL
  const videoURL = youTubeURL + 'embed/' + youtubeProps?.field?.value;

  return (
    <div className={youtubeProps.class}>
      <ReactPlayer
        className={reactPlayer()}
        url={videoURL}
        muted={youtubeProps?.mute}
        onStart={() => {
          sendGTMEvent({
            event: 'media',
            type: 'video',
            'gtm.element.dataset.gtmLinkUrl': videoURL,
          });
        }}
        onPlay={() => {
          youtubeProps.gtmEvent &&
            sendGTMEvent({
              ...youtubeProps.gtmEvent,
              type: 'playing',
            });
        }}
        onPause={() => {
          youtubeProps.gtmEvent &&
            sendGTMEvent({
              ...youtubeProps.gtmEvent,
              type: 'paused',
            });
        }}
        height={responsive ? '100%' : ''}
        width={responsive ? '100%' : ''}
      />
    </div>
  );
};

export default memo(Video);
