// Global
import { sendGTMEvent } from '@next/third-parties/google';
import { Splide, SplideTrack, SplideSlide, Options } from '@splidejs/react-splide';
import '@splidejs/react-splide/css/core';
import React, { forwardRef, useEffect, useState } from 'react';
import { tv } from 'tailwind-variants';
import { merge } from 'lodash';

// Lib
import { GtmEvent } from 'lib/utils/gtm-utils';

// Local
import GoogleMaterialSymbol from 'helpers/GoogleMaterialSymbol/GoogleMaterialSymbol';

/** Enforce gtmEvent props if options.arrows = true */
type ConditionalGtmEvent =
  | {
      gtmEvent: GtmEvent;
    }
  | {
      options?: {
        arrows: false;
      };
      gtmEvent?: never;
    };

/**
 * Represents a Carousel object.
 * @interface
 */
interface CarouselProps {
  autoPlay?: string;
  disclaimer?: JSX.Element;
  children: JSX.Element[];
  options?: Options;
  /** Defaults to false (to provide a custom track element) */
  hasTrack?: boolean;
  /** Pass refs if carousel needs to control another carousel */
  ref?: React.RefObject<Splide>;
  /** A string of classes for the main carousel wrapping element */
  carouselClasses?: string;
  /** A string of classes for the carousel track element */
  trackClasses?: string;
  /** A string of classes for the each slide element */
  slideClasses?: string;
  carouselControls?: CarouselControlProps;
}

interface CarouselControlProps {
  controlContainerClasses?: string;
  paginationContainerClasses?: string;
  /** A string of classes for the individual navigation buttons */
  buttonClasses?: string;
  buttons: Buttons;
  gtmEvent?: GtmEvent;
  autoPlay?: string;
  progressWidth?: string;
  displayDefaultPagination?: boolean;
  paginationVariant?: 'white' | 'color';
  navigationButtonVariant?: 'white' | 'brand';
  showControls?: number;
}

interface Buttons {
  classes?: string;
  prev: {
    icon: string;
  };
  next: {
    icon: string;
  };
  playPause?: string;
}

type Props = CarouselProps & ConditionalGtmEvent;

const defaultCarouselControls: CarouselControlProps = {
  controlContainerClasses:
    'flex gap-6 md:px-components-hero-homepage-spacing-large-padding-x [&+div]:mt-spacing-spacing-5 [&+div]:md:mt-components-hero-homepage-spacing-large-content-margin-bottom',
  paginationContainerClasses: 'flex gap-2',
  buttonClasses:
    'group w-[2.5rem] h-[2.5rem] border-[3px] rounded-themes-radius-large-button mr-2 focus:outline-none focus:ring items-center justify-center inline-flex focus-visible:shadow-focus focus-visible:outline-0',
  buttons: {
    classes: '',
    prev: {
      icon: 'chevron_left',
    },
    next: {
      icon: 'chevron_right',
    },
  },
  paginationVariant: 'white',
};

const defaultCarouselOptions: CarouselProps['options'] = {
  type: 'fade',
  rewind: true,
  autoplay: true,
  drag: false,
  isNavigation: false,
  interval: 3600,
  classes: {
    pagination: 'splide__pagination mr-4',
    page: 'w-9 h-1 bg-gradient-to-r via-[percentage:50%,50%] bg-[position:100%_0%] bg-[length:200%] aria-selected:bg-[position:0%_0%] aria-selected:transition-[background-position] aria-selected:duration-[4000ms] rounded-themes-radius-large-button gap-2 focus-visible:shadow-focus focus-visible:outline-0',
  },
  mediaQuery: 'min',
  breakpoints: {
    996: {
      padding: {
        left: '5rem',
        right: '5rem',
      },
    },
  },
  arrows: true,
  pagination: true,
  autoHeight: true,
};

const carouselControlsTailwindVariants = tv({
  slots: {
    paginationWrapper: [
      'align-start',
      'flex',
      'flex-col',
      'gap-spacing-spacing-3',
      'justify-between',
    ],
  },
});

const navigationButtonTailwindVariants = tv({
  slots: {
    navigationButton: ['disabled:opacity-50'],
    navigationButtonIcon: [],
  },
  variants: {
    variant: {
      brand: {
        navigationButton: [
          'disabled:border-components-button-color-outline-brand-disabled-stroke',
          'disabled:pointer-events-none',
          'border-components-button-color-outline-brand-default-stroke',
          'hover:border-components-button-color-outline-brand-hover-stroke',
          'hover:bg-components-button-color-outline-brand-hover-bg',
          'active:bg-components-button-color-outline-brand-focus-bg',
          'active:border-components-button-color-outline-brand-focus-stroke',
          'focus:ring-components-button-color-outline-brand-focus-outline',
        ],
        navigationButtonIcon: [
          'fill-components-button-color-outline-brand-default-icon',
          'group-hover:fill-components-button-color-outline-brand-hover-icon',
          'group-active:fill-components-button-color-outline-brand-focus-icon',
        ],
      },
      white: {
        navigationButton: [
          'disabled:border-components-button-color-outline-white-disabled-stroke',
          'disabled:pointer-events-none',
          'border-components-button-color-outline-white-default-stroke',
          'hover:border-components-button-color-outline-white-hover-stroke',
          'hover:bg-components-button-color-outline-white-hover-bg',
          'active:bg-components-button-color-outline-white-focus-bg',
          'active:border-components-button-color-outline-white-focus-stroke',
          'focus:ring-components-button-color-outline-white-focus-outline',
        ],
        navigationButtonIcon: [
          'fill-components-button-color-outline-white-default-icon',
          'group-hover:fill-components-button-color-outline-white-hover-icon',
          'group-active:fill-components-button-color-outline-white-focus-icon',
        ],
      },
    },
  },
});

const progressBarTailwindVariants = tv({
  slots: {
    carouselProgressBar: [
      'progress-bar',
      'h-[4px]',
      'w-[160px]',
      'rounded-components-pagination-scrollbar-radius',
    ],
    carouselProgress: ['h-[4px]', 'rounded-components-pagination-scrollbar-radius'],
    page: [],
  },
  variants: {
    variant: {
      color: {
        carouselProgressBar: ['bg-components-pagination-on-color-bg'],
        carouselProgress: ['bg-components-pagination-on-color-accent-scroll'],
        navigationButton: [],
        page: [
          '[&_button]:from-components-pagination-on-color-accent-default',
          '[&_button]:via-components-pagination-on-color-accent-default',
          '[&_button]:to-components-pagination-on-color-bg',
        ],
      },
      white: {
        carouselProgressBar: ['bg-components-pagination-on-white-bg'],
        carouselProgress: ['bg-components-pagination-on-white-accent-scroll'],
        navigationButton: [],
        page: [
          '[&_button]:from-components-pagination-on-white-accent-scroll',
          '[&_button]:via-components-pagination-on-white-accent-scroll',
          '[&_button]:to-components-pagination-on-white-bg',
        ],
      },
    },
  },
});

const CarouselControls = (props: CarouselControlProps): JSX.Element => {
  const { gtmEvent, navigationButtonVariant, paginationVariant, progressWidth, showControls } =
    props;
  const { paginationWrapper } = carouselControlsTailwindVariants();
  const [isHovered, setIsHovered] = useState(false);
  const handleOnClick = ({ clickDirection }: { clickDirection: string }) => {
    sendGTMEvent({
      ...gtmEvent,
      event: 'carousel',
      'gtm.element.dataset.gtmCarouselNavigationClickDirection': clickDirection,
    });
  };

  const { carouselProgressBar, carouselProgress, page } = progressBarTailwindVariants({
    variant: paginationVariant,
  });

  const { navigationButton, navigationButtonIcon } = navigationButtonTailwindVariants({
    variant: navigationButtonVariant,
  });

  const showControlsClass =
    showControls && showControls > 3
      ? ''
      : showControls === 3
      ? ' flex md:hidden'
      : showControls === 2
      ? ' flex sm:hidden'
      : ' hidden';

  return (
    // container
    <div
      className={`${props.controlContainerClasses}${
        props.displayDefaultPagination ? '' : showControlsClass
      }`}
    >
      <div className={paginationWrapper()}>
        {/* pause/play */}
        {props.autoPlay && (
          <button
            className="splide__toggle flex w-fit 
            focus-visible:shadow-focus
            focus-visible:outline-0 rounded-full"
            type="button"
            onMouseEnter={() => setIsHovered(true)}
            onMouseLeave={() => setIsHovered(false)}
          >
            <GoogleMaterialSymbol
              icon="pause_circle"
              fill={isHovered}
              className={`splide__toggle__pause ${props.buttons.playPause}`}
            />
            <GoogleMaterialSymbol
              icon="play_circle"
              fill={isHovered}
              className={`splide__toggle__play ${props.buttons.playPause}`}
            />
          </button>
        )}
        {/* pagination */}
        {props.displayDefaultPagination ? (
          <ul className={`splide__pagination ${props.paginationContainerClasses} ${page()}`}></ul>
        ) : (
          <div className={carouselProgressBar()}>
            <div className={carouselProgress()} style={{ width: progressWidth }}></div>
          </div>
        )}
      </div>
      {/* arrows */}
      <div className="splide__arrows">
        <button
          className={`splide__arrow splide__arrow--prev ${
            props.buttonClasses
          } ${navigationButton()}`}
          onClick={() => handleOnClick({ clickDirection: 'previous' })}
        >
          <GoogleMaterialSymbol
            icon={props.buttons.prev.icon}
            className={`${props.buttons.classes} ${navigationButtonIcon()}`}
          />
        </button>
        <button
          className={`splide__arrow splide__arrow--next ${
            props.buttonClasses
          } ${navigationButton()}`}
          onClick={() => handleOnClick({ clickDirection: 'next' })}
        >
          <GoogleMaterialSymbol
            icon={props.buttons.next.icon}
            className={`${props.buttons.classes} ${navigationButtonIcon()} flex justify-center`}
          />
        </button>
      </div>
    </div>
  );
};

export const Carousel = forwardRef((props: Props, ref: React.RefObject<Splide>): JSX.Element => {
  const {
    autoPlay,
    carouselClasses,
    carouselControls: carouselControlsProps,
    children,
    disclaimer,
    gtmEvent,
    hasTrack,
    options,
    trackClasses,
  } = props;

  const carouselControls = merge({}, defaultCarouselControls, carouselControlsProps);
  carouselControls.autoPlay = autoPlay;

  const mergedCarouselOptions = merge({}, defaultCarouselOptions, options);

  if (children.length <= 1) {
    mergedCarouselOptions.autoplay = false;
    mergedCarouselOptions.arrows = false;
    mergedCarouselOptions.pagination = false;
  }
  mergedCarouselOptions.reducedMotion = {
    autoplay: false,
  };

  const [windowSize, setWindowSize] = useState<number>(0);

  useEffect(() => {
    if (typeof window === undefined) {
      return;
    }

    const handleResize = () => {
      setWindowSize(window.innerWidth);
    };

    window.addEventListener('resize', handleResize);

    handleResize();

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [windowSize]);

  const [progressWidth, setProgressWidth] = useState('0%');

  return (
    <Splide
      data-component="authorable/carousel"
      ref={ref}
      hasTrack={hasTrack}
      options={mergedCarouselOptions}
      className={carouselClasses}
      onMove={(splide) => {
        const end = splide?.Components?.Controller?.getEnd() + 1;
        const rate = Math.min((splide?.index + 1) / end, 1);
        setProgressWidth(`${100 * rate}%`);
      }}
      onMounted={(splide) => {
        const end = splide?.Components?.Controller?.getEnd() + 1;
        const rate = Math.min((splide?.index + 1) / end, 1);
        setProgressWidth(`${100 * rate}%`);
        if (autoPlay === 'On') {
          splide.Components.Autoplay.play();
        } else {
          splide.Components.Autoplay.pause();
        }
      }}
      onPaginationMounted={(_splide, data, _item) => {
        data.items.forEach((paginationListItem) => {
          paginationListItem.li.style.display = 'flex';
          if (mergedCarouselOptions.rewind) {
            paginationListItem.button.tabIndex = 0;
          }
        });
      }}
      onPaginationUpdated={(_splide, data, _item) => {
        data.items.forEach((paginationListItem) => {
          if (mergedCarouselOptions.rewind) {
            paginationListItem.button.tabIndex = 0;
          }
        });
      }}
      onResize={(splide) => {
        if (window && window.innerWidth > 996 && children.length <= 3) {
          splide.go(0);
        }
        const end = splide?.Components?.Controller?.getEnd() + 1;
        const rate = Math.min((splide?.index + 1) / end, 1);
        setProgressWidth(`${100 * rate}%`);
      }}
    >
      <SplideTrack className={trackClasses}>
        {children.map((child, index) => {
          return <SplideSlide key={index}>{child}</SplideSlide>;
        })}
      </SplideTrack>
      {mergedCarouselOptions.autoplay && (
        <CarouselControls
          showControls={children.length}
          displayDefaultPagination={mergedCarouselOptions.pagination}
          progressWidth={progressWidth}
          {...carouselControls}
          gtmEvent={gtmEvent}
        />
      )}
      {disclaimer && disclaimer}
    </Splide>
  );
});

export default Carousel;

Carousel.displayName = 'Carousel';
