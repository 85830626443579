// Global
import { LinkField, Text } from '@sitecore-jss/sitecore-jss-nextjs';
import React from 'react';
import { tv } from 'tailwind-variants';

// Lib
import { DartComponents } from 'lib/templates/Feature.Dart.model';
import { ComponentProps } from 'lib/component-props';

// Local
import Container from 'components/authorable/Layout/DartContainer/DartContainer';
import Button from 'helpers/Button/Button';
import ImageWrapper from 'helpers/ImageWrapper/ImageWrapper';
import Label from 'helpers/Label/Label';
import RichTextA11yWrapper from 'helpers/RichTextA11yWrapper/RichTextA11yWrapper';
import Video from 'helpers/Video/Video';
import LegalDisclaimer from 'helpers/LegalDisclaimer/LegalDisclaimer';
import fallback from 'lib/fallback/fallback';

export type PromoProps = ComponentProps & DartComponents.Promo.Promo;

type AlignmentType = 'Center' | 'Left' | 'Right';

type BgColorType = 'Light' | 'BrandColor' | 'Default' | undefined;

type Media = 'video' | 'image';

// Tailwind variables
const tailwindVariants = tv({
  slots: {
    base: [
      'flex',
      'gap-components-promo-spacing-small-content-space-between',
      'items-center',
      'justify-start',
      'relative',
      'left',
      'pt-components-promo-spacing-small-padding-image-top',
      'pb-components-promo-spacing-small-padding-y',
      'md:gap-components-promo-spacing-large-content-space-between',
      'md:py-components-promo-spacing-large-padding-y',
      'md:px-components-promo-spacing-large-padding-x',
    ],
    componentBG: [],
    contentBlock: [
      'flex',
      'flex-col',
      'gap-components-promo-spacing-large-title-margin-bottom',
      'justify-start',
      'self-stretch',
      'shrink-0',
      'relative',
    ],
    contentContainer: [
      'flex',
      'flex-col',
      'gap-components-promo-spacing-large-margin-y',
      'items-start',
      'justify-start',
      'self-stretch',
      'shrink-0',
      'relative',
    ],
    ctaContainer: [
      'flex',
      'flex-row',
      'flex-wrap',
      'gap-components-promo-spacing-large-buttons-space-between',
      'items-start',
      'justify-start',
      'shrink-0',
      'relative',
    ],
    descriptionContainer: ['text-left', 'relative', 'self-stretch'],
    descriptionContainerText: [
      'md:font-bodySans-medium',
      'md:leading-bodySans-medium',
      'font-bodySans-medium',
      'leading-bodySans-medium',
    ],
    headlineContainer: [
      'font-header-small-medium',
      'leading-header-small-medium',
      'relative',
      'self-stretch',
      'text-header-small-medium',
      'text-left',
      'md:font-header-large-medium',
      'md:leading-header-large-medium',
      'md:text-header-large-medium',
    ],
    labelContainer: ['[&>div]:!mb-0'],
    mediaContainer: [
      'flex',
      'flex-col',
      'gap-0',
      'items-center',
      'justify-center',
      'flex-1',
      'relative',
      'overflow-hidden',
      '[&>div]:!w-full',
      '[&>img]:rounded-tl-components-promo-spacing-small-image-radius-tl',
      '[&>img]:rounded-tr-components-promo-spacing-small-image-radius-tr',
      '[&>img]:rounded-bl-components-promo-spacing-small-image-radius-bl',
      '[&>img]:rounded-br-components-promo-spacing-small-image-radius-br',
      '[&>img]:md:rounded-tl-components-promo-spacing-large-image-radius-tl',
      '[&>img]:md:rounded-tr-components-promo-spacing-large-image-radius-tr',
      '[&>img]:md:rounded-bl-components-promo-spacing-large-image-radius-bl',
      '[&>img]:md:rounded-br-components-promo-spacing-large-image-radius-br',
      'md:w-auto',
      'w-full',
      'px-components-promo-spacing-small-padding-x',
      'md:px-0',
    ],
    wrapper: [
      'pt-components-promo-spacing-small-padding-content-y',
      'pr-components-promo-spacing-small-padding-content-x',
      'pb-components-promo-spacing-small-padding-content-y',
      'pl-components-promo-spacing-small-padding-content-x',
      'md:pt-components-promo-spacing-large-padding-content-y',
      'md:pr-components-promo-spacing-large-padding-content-x',
      'md:pb-components-promo-spacing-large-padding-content-y',
      'md:pl-components-promo-spacing-large-padding-content-x',
      'flex',
      'flex-col',
      'gap-components-promo-spacing-large-margin-y',
      'items-start',
      'justify-center',
      'flex-1',
      'relative',
    ],
  },
  variants: {
    alignment: {
      Center: {
        base: ['flex-col-reverse'],
        contentBlock: ['md:items-center'],
        contentContainer: ['md:items-center'],
        ctaContainer: ['md:flex', 'md:justify-center', 'md:w-full'],
        descriptionContainer: ['md:text-center'],
        descriptionContainerText: ['md:text-center'],
        headlineContainer: ['md:text-center', 'md:justify-center'],
        labelContainer: ['md:flex', 'md:justify-center', 'md:w-full'],
        mediaContainer: ['md:!w-[41%]'],
      },
      Left: {
        base: ['md:flex-row', 'flex-col-reverse'],
      },
      Right: {
        base: ['md:flex-row-reverse', 'flex-col-reverse'],
      },
    },
    background: {
      BrandColor: {
        base: 'bg-components-promo-color-brand-bg',
        componentBG: 'bg-components-promo-color-brand-bg',
        descriptionContainerText: [
          'md:text-bodySans-medium',
          'text-bodySans-medium',
          'text-components-promo-color-brand-body',
        ],
        headlineContainer: ['text-components-promo-color-brand-title'],
      },
      Default: {
        base: 'bg-components-promo-color-default-bg',
        componentBG: 'bg-components-promo-color-default-bg',
        descriptionContainerText: [
          'md:text-bodySans-medium',
          'text-bodySans-medium',
          'text-components-promo-color-default-body',
        ],
        headlineContainer: ['text-components-promo-color-default-title'],
      },
      Light: {
        base: 'bg-components-promo-color-light-bg',
        componentBG: 'bg-components-promo-color-light-bg',
        descriptionContainerText: [
          'text-bodySans-medium',
          'md:text-bodySans-medium',
          'text-components-promo-color-light-body',
        ],
        headlineContainer: ['text-components-promo-color-light-title'],
      },
    },
    media: {
      video: {
        mediaContainer: ['aspect-video'],
      },
      image: {
        mediaContainer: ['aspect-[4/3]'],
      },
    },
  },
});

// Add fallback component variant color
const fallbackComponentVariantColor = fallback?.componentVariants?.value;
const fallbackComponentVariantType = fallback?.componentVariants?.type;

const DartPromo = (props: PromoProps): JSX.Element => {
  const {
    description,
    headline,
    image,
    label,
    primaryCTA,
    secondaryCTA,
    video,
    disclaimerText,
    primaryCTAColor,
    primaryCTAType,
    secondaryCTAColor,
    secondaryCTAType,
  } = props?.fields || {};
  const { componentName, dataSource } = props?.rendering || {};
  const { alignContent, backgroundColor, RenderingIdentifier } = props?.params || {};

  if (!props.fields) return <>Promo Component</>;

  const id = RenderingIdentifier;
  const labelLink = label?.fields?.link as LinkField;
  const media = video?.value ? 'video' : 'image';

  const {
    base,
    componentBG,
    contentBlock,
    contentContainer,
    ctaContainer,
    descriptionContainer,
    descriptionContainerText,
    headlineContainer,
    labelContainer,
    mediaContainer,
    wrapper,
  } = tailwindVariants({
    alignment: alignContent as AlignmentType,
    background: backgroundColor as BgColorType,
    media: media as Media,
  });

  return (
    <div className={componentBG()} data-component="authorable/dartpromo" id={id ? id : undefined}>
      <Container>
        <div className={base()}>
          <div className={wrapper()}>
            <div className={contentContainer()}>
              {labelLink?.value?.href && (
                <Label
                  className={labelContainer()}
                  color={backgroundColor === 'BrandColor' ? 'color' : 'white'}
                  backgroundColor={
                    tailwindVariants.variants.background[
                      backgroundColor as keyof typeof tailwindVariants.variants.background
                    ].base
                  }
                  link={label?.fields?.link as LinkField}
                  gtmEvent={{
                    event: 'link',
                    type: 'label',
                    'gtm.element.dataset.gtmDatasourceId': labelLink.value.id as string,
                    'gtm.element.dataset.gtmComponentName': labelLink.value.text,
                  }}
                />
              )}

              <div className={contentBlock()}>
                {headline?.value.length && (
                  <div className={headlineContainer()}>
                    <Text encode={false} field={headline} tag="h2" />
                  </div>
                )}
                {description?.value.length && (
                  <div className={(descriptionContainer(), descriptionContainerText())}>
                    <RichTextA11yWrapper field={description} />
                  </div>
                )}
              </div>
            </div>
            {(primaryCTA?.value?.text || secondaryCTA?.value?.text) && (
              <div className={ctaContainer()}>
                {primaryCTA?.value?.text?.length && (
                  <>
                    <Button
                      //As the design requires a filled CTA, we do not need to add a fallback since
                      //the button component has a default variant that displays a filled CTA.
                      type={primaryCTAType?.value as string}
                      color={backgroundColor === 'BrandColor' ? 'white' : primaryCTAColor?.value}
                      label={primaryCTA?.value?.text}
                      title={primaryCTA?.value?.title}
                      tag="a"
                      href={primaryCTA?.value?.href}
                      gtmEvent={{
                        event: 'cta_click',
                        type: 'primary',
                        'gtm.element.dataset.gtmLinkUrl': primaryCTA?.value?.href,
                        'gtm.element.dataset.gtmLinkName': primaryCTA?.value?.text,
                        'gtm.element.dataset.gtmDatasourceId': dataSource,
                        'gtm.element.dataset.gtmComponentName': componentName,
                      }}
                    />
                  </>
                )}
                {secondaryCTA?.value?.text?.length && (
                  <>
                    <Button
                      //The design requires an outline CTA, so for that we have added a fallback as an outline value,
                      //so if there is no value in sitecore field, it will take the outline value.
                      type={secondaryCTAType?.value || fallbackComponentVariantType}
                      color={
                        backgroundColor === 'BrandColor'
                          ? 'white'
                          : secondaryCTAColor?.value || fallbackComponentVariantColor
                      }
                      label={secondaryCTA?.value?.text}
                      title={secondaryCTA?.value?.title}
                      tag="a"
                      href={secondaryCTA?.value?.href}
                      gtmEvent={{
                        event: 'cta_click',
                        type: 'secondary',
                        'gtm.element.dataset.gtmLinkUrl': secondaryCTA?.value?.href,
                        'gtm.element.dataset.gtmLinkName': secondaryCTA?.value?.text,
                        'gtm.element.dataset.gtmDatasourceId': dataSource,
                        'gtm.element.dataset.gtmComponentName': componentName,
                      }}
                    />
                  </>
                )}
              </div>
            )}
            {disclaimerText?.value != '' && <LegalDisclaimer disclaimerText={disclaimerText} />}
          </div>

          <div className={mediaContainer()}>
            {image?.value?.src?.length && !video?.value?.length && (
              <ImageWrapper field={image} layout="responsive" />
            )}
            {video?.value?.length ? (
              <Video
                responsive
                class="h-full"
                field={video}
                gtmEvent={{
                  event: 'media',
                  type: 'video',
                  'gtm.element.dataset.gtmLinkName': video?.value,
                  'gtm.element.dataset.gtmDatasourceId': dataSource,
                  'gtm.element.dataset.gtmComponentName': componentName,
                }}
              />
            ) : null}
          </div>
        </div>
      </Container>
    </div>
  );
};

export default DartPromo;
