// Global
import { RichText, Text, useSitecoreContext } from '@sitecore-jss/sitecore-jss-nextjs';
import axios from 'axios';
import { useRouter } from 'next/router';
import React, { LegacyRef, useState } from 'react';
import ReCAPTCHA from 'react-google-recaptcha';
import { tv } from 'tailwind-variants';
import { useI18n } from 'next-localization';

// Lib
import { ComponentProps } from 'lib/component-props';
import { DartComponents } from 'lib/templates/Feature.Dart.model';

// Local
import Button from 'helpers/Button/Button';
import { LocationSearchData } from 'helpers/GlobalContext/globalContext';
import ImageWrapper from 'helpers/ImageWrapper/ImageWrapper';
import OffcastSearchInput from 'helpers/OffcastSearchInput/OffcastSearchInput';
import { SubscribeAPIConfig } from './MosquitoForecastResult';
import GetLanguageCode from 'helpers/GetLanguageCode/GetLanguageCode';
import GetGoogleCaptcha from 'helpers/GoogleCaptcha/GetGoogleCaptcha';

export type MosquitoForecastNewsletterProps = ComponentProps &
  DartComponents.MosquitoForecastNewsletter.MosquitoForecastNewsletter;

const tailwindVariants = tv({
  slots: {
    bgContainer: [
      'flex',
      'items-center',
      'left-0',
      'overflow-hidden',
      'top-0',
      'w-full',
      'md:absolute',
      'md:h-full',
      'md:rounded-3xl',
    ],
    bgImage: ['left-0', 'relative', 'w-full', 'h-full', 'rounded-2xl'],
    consentCheckbox: [
      'bg-forms-checkbox-color-checkbox-default',
      'bg-gray-100',
      'border-gray-300',
      'border',
      'h-6',
      'rounded-forms-checkbox-spacing-radius',
      'text-blue-600',
      'w-6',
      'shrink-0',
    ],
    consentContainer: [
      'flex',
      'flex-col',
      'gap-spacing-margin-large-3',
      'sm:items-center',
      'md:items-start',
      'justify-center',
      'pt-spacing-margin-large-2',
      'self-stretch',
    ],
    consentSection: [
      'flex',
      'gap-forms-checkbox-spacing-space-between',
      'items-start',
      'mb-4',
      'md:w-[355px]',
    ],
    consentText: [
      'flex-1',
      'font-bodySans-medium',
      'leading-bodySans-medium',
      'text-bodySans-medium',
      'text-components-contact-us-promo-color-default-title',
      '[&_a]:font-bodySans-medium-underline',
      '[&_a]:leading-bodySans-medium-underline',
      '[&_a]:text-bodySans-medium-underline',
      '[&_a]:text-components-hyperlink-color-dark-default',
      '[&_a]:inline-block',
    ],
    contentSection: [
      'bg-color-grayscale-base-white',
      'flex-col',
      'flex',
      'gap-spacing-space-between-large-4',
      'px-spacing-padding-small-5',
      'py-spacing-padding-medium-5',
      'rounded-2xl',
      'md:p-spacing-padding-large-5',
      'relative',
    ],
    consentSections: [
      'flex',
      'flex-col',
      'md:flex-row',
      'gap-6',
      'sm:items-center',
      'md:items-start',
    ],
    emailSection: ['p-10'],
    emailTitleText: [
      'font-header-small-medium',
      'leading-header-small-medium',
      'mb-10',
      'text-center',
      'text-components-contact-us-promo-color-default-title',
      'text-header-small-medium',
    ],
    formButton: [
      'bg-components-button-color-filled-brand-default-bg',
      'font-bodySans-medium-semibold',
      'h-12',
      'px-components-button-spacing-large-default-padding-x',
      'rounded-themes-radius-large-button',
      'text-components-button-color-filled-brand-default-text',
      'py-components-button-spacing-large-default-padding-y',
    ],
    formContainer: [
      'flex',
      'flex-col',
      'gap-spacing-space-between-large-3',
      'relative',
      'md:flex-row',
      '[&_input]:w-full',
      'justify-between',
      'md:items-center',
      'items-stretch',
    ],
    emailInput: [
      'border',
      'flex-1',
      'min-h-14',
      'focus:-m-px',
      'px-components-text-field-input-padding-x',
      'py-components-text-field-input-padding-y',
      'rounded-full',
      'self-stretch',
      'text-bodySans-medium',
      'font-bodySans-medium',
      'leading-bodySans-medium',
      'text-components-text-field-input-field-default',
      'focus:text-components-text-field-input-field-focused',
      'focus:border-2',
      'focus:outline-none',
      'focus:bg-components-text-field-bg-focused',
    ],
    mainContainer: [
      'md:px-components-contact-us-promo-spacing-large-padding-x',
      'md:py-components-contact-us-promo-spacing-large-padding-y',
    ],
    newsletterContainer: [
      'flex-col',
      'flex',
      'sm:justify-center',
      'lg:justify-end',
      'p-spacing-padding-large-3',
      'relative',
      'md:flex-row',
      'md:px-spacing-padding-large-4',
      'md:py-spacing-padding-large-4',
      'md:rounded-3xl',
    ],
    offCastLogo: ['text-center'],
    titleText: [
      'text-header-small-medium',
      'font-header-small-medium',
      'leading-header-small-medium',
      'font-extrabold',
      'text-components-contact-us-promo-color-default-title',
    ],
    emailErrorText: [
      'font-bodySans-xSmall',
      'text-bodySans-xSmall',
      'leading-bodySans-xSmall',
      'text-components-text-field-supporting-text-destructive',
      'mt-components-text-field-supporting-text-padding-top',
    ],
    locationErrorText: [
      'font-bodySans-xSmall',
      'text-bodySans-xSmall',
      'leading-bodySans-xSmall',
      'text-components-text-field-supporting-text-destructive',
      'mt-components-text-field-supporting-text-padding-top',
    ],
    verificationErrorText: [
      'font-bodySans-xSmall',
      'text-bodySans-xSmall',
      'leading-bodySans-xSmall',
      'text-components-text-field-supporting-text-destructive',
      'mt-components-text-field-supporting-text-padding-top',
    ],
  },
  variants: {
    showEmailAndVerificationError: {
      true: {
        emailErrorText: ['block'],
        emailInput: [
          'border-components-text-field-border-destructive',
          'focus:border-components-text-field-border-destructive',
        ],
      },
      false: {
        emailErrorText: ['hidden'],
        emailInput: [
          'border-components-text-field-border-default',
          'focus:border-components-text-field-border-focused',
        ],
      },
    },
    showLocationError: {
      true: {
        locationErrorText: ['block'],
      },
      false: {
        locationErrorText: ['hidden'],
      },
    },
    showVerificationError: {
      true: {
        verificationErrorText: ['block'],
      },
      false: {
        verificationErrorText: ['hidden'],
      },
    },
    showConsentContainer: {
      true: {
        consentContainer: ['md:flex-col'],
      },
      false: {
        consentContainer: ['md:flex-row'],
      },
    },
  },
});

const MosquitoForecastNewsletter = (props: MosquitoForecastNewsletterProps): JSX.Element => {
  const _reCaptchaRef = React.createRef();

  const [captchaValue, setCaptchaValue] = useState<string | null>(null);
  const [consentCheck, setConsentCheck] = useState(false);
  const [promotionCheck, setPromotionCheck] = useState(false);
  const [subscribeEmail, setSubscribeEmail] = useState('');
  const [selectedPlace, setSelectedPlace] = useState<LocationSearchData>();
  const [showEmailError, setShowEmailError] = useState(false);
  const [showVerificationError, setShowVerificationError] = useState(false);
  const [showLocationError, setShowLocationError] = useState(false);

  const { sitecoreContext } = useSitecoreContext();
  const router = useRouter();
  const i18n = useI18n();
  const emailMsg = i18n.t('EmailAddressRequired');
  const failedToFetchMsg = i18n.t('FailedToFetch');
  const validEmailMsg = i18n.t('ValidEmailAddress');
  const verificationReqMsg = i18n.t('VerificationRequired');
  const locationReqMsg = i18n.t('LocationRequired');

  if (!props.fields) return <>MosquitoForecastPromo Component</>;

  const {
    backgroundImage,
    title,
    primaryCTA,
    primaryCTAColor,
    primaryCTAType,
    emailPlaceholderText,
    locationPlaceholderText,
    newsletterAcceptanceText,
    promotionsOffersText,
    disableCaptcha,
  } = props?.fields;

  const isPromotionNotEmpty = promotionsOffersText?.value?.toString() != '' ? true : false;

  const {
    bgContainer,
    bgImage,
    consentCheckbox,
    consentContainer,
    consentSection,
    consentText,
    contentSection,
    consentSections,
    formContainer,
    emailInput,
    locationErrorText,
    emailErrorText,
    verificationErrorText,
    mainContainer,
    newsletterContainer,
    titleText,
  } = tailwindVariants({
    showEmailAndVerificationError: !subscribeEmail && showEmailError,
    showLocationError: !selectedPlace && showLocationError ? true : false,
    showVerificationError: (!consentCheck || !captchaValue) && showVerificationError,
    showConsentContainer: isPromotionNotEmpty,
  });

  const onChaptchaChange = (value: string | null) => {
    setCaptchaValue(value);
  };

  const onCheckConsent = (e: React.ChangeEvent<HTMLInputElement>) => {
    setConsentCheck(e.currentTarget.checked);
  };

  const onPromotionConsent = (e: React.ChangeEvent<HTMLInputElement>) => {
    setPromotionCheck(e.currentTarget.checked);
  };

  const onChangeEmail = (e: React.ChangeEvent<HTMLInputElement>) => {
    let emailVal = e.currentTarget.value;
    if (!validateEmail(e.currentTarget.value)) emailVal = '';
    setSubscribeEmail(emailVal);
  };

  const absolutePath = function (href: string) {
    const link = document.createElement('a');
    link.href = href;
    return link.protocol + '//' + link.host + link.pathname + link.search + link.hash;
  };

  const validateEmail = (email: string) => {
    return String(email)
      .toLowerCase()
      .match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      );
  };

  const onSubscribeNewsletter = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setShowEmailError(false);
    setShowVerificationError(false);
    setShowLocationError(false);

    if (!subscribeEmail) {
      setShowEmailError(true);
      return;
    }
    if (!selectedPlace) {
      setShowLocationError(true);
      return;
    }
    if (!consentCheck || !captchaValue) {
      setShowVerificationError(true);
      return;
    }

    const { successUrl, errorUrl, url, countryCode } =
      sitecoreContext?.mosquitoForecastSFMC as SubscribeAPIConfig;

    try {
      const payload = {
        successURL: absolutePath(successUrl),
        errorURL: absolutePath(errorUrl),
        countryCode: countryCode,
        latitude: selectedPlace?.latitude,
        longitude: selectedPlace?.longitude,
        emailAddress: subscribeEmail,
        apiURL: url,
        emailMsg,
        failedToFetchMsg,
        placeId: selectedPlace?.placeId,
        captchaValue: captchaValue,
        TermsAndConditions: consentCheck,
      };

      const payloadWithPromotion = {
        ...payload,
        Promotions_Offers: promotionCheck,
      };

      const apiResponse = await axios.get('/api/climatecompany/subscribe', {
        params: isPromotionNotEmpty ? payloadWithPromotion : payload,
      });

      return router.push(apiResponse.request.responseURL);
    } catch (error) {
      return error;
    }
  };

  return (
    <div
      id="newsletter-sign-up-landing"
      className={mainContainer()}
      data-component="authorable/mosquitoforecastnewsletter"
    >
      <div className={newsletterContainer()}>
        {backgroundImage?.value?.src && (
          <div className={bgContainer()}>
            <ImageWrapper className={bgImage()} field={backgroundImage} />
          </div>
        )}
        <div className={contentSection()}>
          {title?.value && (
            <div className={titleText()}>
              <Text field={title} />
            </div>
          )}
          <form className={formContainer()} onSubmit={onSubscribeNewsletter}>
            <div>
              <input
                className={emailInput()}
                onChange={onChangeEmail}
                placeholder={emailPlaceholderText?.value}
                type="email"
              />
              <p className={emailErrorText()}>{showEmailError && validEmailMsg}</p>
            </div>
            <div>
              <OffcastSearchInput
                onSelectLocation={setSelectedPlace}
                placeholder={locationPlaceholderText?.value as string}
                selectedPlace={selectedPlace}
                isError={!selectedPlace && showLocationError}
              />

              <p className={locationErrorText()}>{showLocationError && locationReqMsg}</p>
            </div>
            {primaryCTA?.value.text && (
              <Button
                //As the design requires a filled CTA, we do not need to add a fallback since
                //the button component has a default variant that displays a filled CTA.
                label={primaryCTA?.value.text}
                type={primaryCTAType?.value as string}
                color={primaryCTAColor?.value}
              />
            )}
          </form>
          <div className={consentContainer()}>
            <div className={consentSections()}>
              <div className={consentSection()}>
                <input
                  className={consentCheckbox()}
                  id="forcastCheckbox"
                  onChange={onCheckConsent}
                  type="checkbox"
                />
                {newsletterAcceptanceText?.value && (
                  <label className={consentText()} htmlFor="forcastCheckbox">
                    <RichText field={newsletterAcceptanceText} />
                    <div className={verificationErrorText()}>
                      {showVerificationError && !consentCheck && verificationReqMsg}
                    </div>
                  </label>
                )}
              </div>
              {isPromotionNotEmpty && (
                <div className={consentSection()}>
                  <input
                    className={consentCheckbox()}
                    id="forcastCheckbox"
                    onChange={onPromotionConsent}
                    type="checkbox"
                  />
                  {promotionsOffersText?.value && (
                    <label className={consentText()} htmlFor="forcastCheckbox">
                      <RichText field={promotionsOffersText} />
                    </label>
                  )}
                </div>
              )}
            </div>
            <div>
              {!disableCaptcha?.value && (
                <ReCAPTCHA
                  onChange={onChaptchaChange}
                  ref={_reCaptchaRef as LegacyRef<ReCAPTCHA>}
                  sitekey={GetGoogleCaptcha()}
                  style={{ display: 'inline-block' }}
                  hl={GetLanguageCode(sitecoreContext?.language)}
                />
              )}
              <div className={verificationErrorText()}>
                {showVerificationError && !captchaValue && verificationReqMsg}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MosquitoForecastNewsletter;
