// Global
import { tv } from 'tailwind-variants';

export interface MulticolorborderProps {
  className?: string;
  borderColorOne?: string;
  borderColorTwo?: string;
  borderColorThree?: string;
  borderColorFour?: string;
}

const MultiColorBorder: React.FC<MulticolorborderProps> = (props) => {
  const { borderColorOne, borderColorTwo, borderColorThree, borderColorFour } = props || {};

  const tailwindVariants = tv({
    slots: {
      base: ['flex', 'h-[11px]', 'overflow-hidden', 'transform'],
      borderOne: [
        borderColorOne,
        'w-1/4',
        'relative',
        'before:absolute',
        'before:content-[""]',
        'before:h-full',
        'before:w-[5px]',
        'before:bg-inherit',
        'before:right-[-5px]',
      ],
      borderTwo: [
        borderColorTwo,
        'w-1/4',
        'skew-x-[40deg]',
        'relative',
        'before:absolute',
        'before:content-[""]',
        'before:h-full',
        'before:w-[5px]',
        'before:bg-inherit',
        'before:right-[-5px]',
      ],
      borderThree: [borderColorThree, 'w-1/4', 'skew-x-[40deg]', 'z-[9]'],
      borderFour: [
        borderColorFour,
        'w-1/4',
        'relative',
        'after:absolute',
        'after:content-[""]',
        'after:h-full',
        'after:w-[5px]',
        'after:bg-inherit',
        'after:left-[-5px]',
      ],
    },
  });

  const { base, borderOne, borderTwo, borderThree, borderFour } = tailwindVariants();

  return (
    <div className={base()}>
      <div className={borderOne()}></div>
      <div className={borderTwo()}></div>
      <div className={borderThree()}></div>
      <div className={borderFour()}></div>
    </div>
  );
};

export default MultiColorBorder;
