// Global
import React, { useEffect, useState } from 'react';

// Lib
import { ComponentProps } from 'lib/component-props';
import { DartComponents } from 'lib/templates/Feature.Dart.model';
import { addDartSvgLoading, removeDartSvgLoading } from 'lib/utils/dart-svg-loading';
import { getStaticAssetUrl } from 'lib/utils/public-url-utils';

// Local
import Container from 'components/authorable/Layout/DartContainer/DartContainer';
import RichTextA11yWrapper from 'helpers/RichTextA11yWrapper/RichTextA11yWrapper';
import LegalDisclaimer from 'helpers/LegalDisclaimer/LegalDisclaimer';

export type RTEProps = ComponentProps & DartComponents.Rte.Rte;

export const Default = (props: RTEProps): JSX.Element => {
  const isFullWidth = props?.params?.isFullWidth;

  const disclaimerText = props?.fields?.disclaimerText;
  const fullWidth = isFullWidth ? true : undefined;
  const regex = new RegExp(
    `(<a\\s+(?:[^>]*?\\s+)?href=["']((https?:\\/\\/|\\/(?!))[^"']*?)["'][^>]*>(.*?)<\\/a>)`,
    'g'
  );
  const [fetchedIcon, setFetchedIcon] = useState('');

  useEffect(() => {
    async function fetchIcon() {
      const iconUri = `${getStaticAssetUrl()}/assets/google-material-symbols/sharp/open_in_new.svg`;

      addDartSvgLoading(iconUri);

      fetch(iconUri)
        .then((res) => (res.ok ? res.text() : null))
        .then((text) =>
          text
            ? setFetchedIcon(text)
            : console.error('There was a problem with the Fetch operation:')
        )
        .catch((error) => {
          console.error('There was a problem with the Fetch operation:', error);
        })
        .finally(() => {
          removeDartSvgLoading(iconUri);
        });
    }

    fetchIcon();
  }, []);

  // Function to replace external links with modified content
  const modifyExternalLinks = (
    _: string,
    _fullMatch: string,
    href: string,
    _protocol: string,
    existingContent: string
  ): string => {
    const updatedContent = `
        <a href="${href}">
          ${existingContent}
          ${fetchedIcon}
        </a>
      `;
    return updatedContent;
  };

  const updatedText = props.fields?.text?.value?.replace(regex, (_match, ...groups) =>
    modifyExternalLinks('', groups[0], groups[1], groups[2], groups[3])
  );

  const text = props.fields ? (
    <RichTextA11yWrapper field={{ ...props.fields?.text, value: updatedText }} />
  ) : (
    <span className="is-empty-hint">Rich text</span>
  );

  const id = props?.params?.RenderingIdentifier;

  return (
    <div
      className={`component rich-text ${props.params.styles.trimEnd()}`}
      data-component="authorable/rte"
      id={id ? id : undefined}
    >
      <Container fullWidth={fullWidth}>
        <div className="py-spacing-spacing-7 px-spacing-spacing-4 md:px-spacing-spacing-2">
          {text}
          {disclaimerText?.value != '' && (
            <LegalDisclaimer disclaimerText={disclaimerText} disclaimerClasses={'pt-5'} />
          )}
        </div>
      </Container>
    </div>
  );
};
