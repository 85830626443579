// Global
import { RichText, Text, useSitecoreContext } from '@sitecore-jss/sitecore-jss-nextjs';
import axios from 'axios';
import React, { useContext, useEffect, useState } from 'react';
import { tv } from 'tailwind-variants';
import { useI18n } from 'next-localization';

// Lib
import { ComponentProps } from 'lib/component-props';
import { DartComponents } from 'lib/templates/Feature.Dart.model';

// Local
import { GlobalContext, LocationSearchData } from 'helpers/GlobalContext/globalContext';
import Button from 'helpers/Button/Button';
import ImageWrapper from 'helpers/ImageWrapper/ImageWrapper';
import OffcastSearchInput from 'helpers/OffcastSearchInput/OffcastSearchInput';
import Container from '../Layout/DartContainer/DartContainer';
import { useRouter } from 'next/router';

export type MosquitoForecastPromoProps = ComponentProps &
  DartComponents.MosquitoForecastPromo.MosquitoForecastPromo;

type ForecastAPIConfig = {
  countryCode: string;
  languageCountryCode: string;
  locationSearchUrl: string;
  mosquitoForecastUrl: string;
};

const tailwindVariants = tv({
  slots: {
    bgContainer: [
      'flex',
      'items-center',
      'left-0',
      'overflow-hidden',
      'top-0',
      'w-full',
      'md:absolute',
      'md:h-full',
    ],
    bgImage: ['left-0', 'relative', 'w-full', 'h-full'],
    componentDescription: [
      'font-bodySans-medium',
      'leading-bodySans-medium',
      'text-bodySans-medium',
      'md:font-bodySans-large',
      'md:leading-bodySans-large',
      'md:text-bodySans-large',
      'text-components-contact-us-promo-color-default-body',
      'text-center',
    ],
    componentTitle: [
      'font-header-small-small',
      'leading-header-small-small',
      'text-header-small-small',
      'md:font-header-large-small',
      'md:leading-header-large-small',
      'md:text-header-large-small',
      'text-components-contact-us-promo-color-default-title',
      'mb-components-contact-us-promo-spacing-small-title-margin-bottom',
      'text-center',
    ],
    formContainer: [
      'flex-col',
      'flex',
      'gap-5',
      'items-stretch',
      'md:items-center',
      'justify-center',
      'md:flex-row',
    ],
    mainContainer: [
      'flex',
      'flex-col',
      'relative',
      'md:py-components-contact-us-promo-spacing-large-padding-y',
      'md:px-components-contact-us-promo-spacing-large-padding-x',
    ],
    offCastImage: ['bg-components-promo-color-default-image-bg', 'mx-auto'],
    promoContainer: [
      'bg-themes-background-color-white',
      'flex-col',
      'flex',
      'gap-spacing-padding-large-6',
      'p-spacing-padding-large-5',
      'w-full',
      'md:max-w-[700px]',
      'md:rounded-2xl',
      'z-20',
    ],
    searchInputContainer: ['md:w-auto', 'w-full'],
    locationErrorText: [
      'font-bodySans-xSmall',
      'text-bodySans-xSmall',
      'leading-bodySans-xSmall',
      'text-components-text-field-supporting-text-destructive',
      'mt-components-text-field-supporting-text-padding-top',
    ],
  },
  variants: {
    showError: {
      true: {
        locationErrorText: ['block'],
      },
      false: {
        locationErrorText: ['hidden'],
      },
    },
  },
});

const MosquitoForecastPromo = (props: MosquitoForecastPromoProps): JSX.Element => {
  const {
    title,
    searchPlaceholderText,
    description,
    primaryCTA,
    primaryCTAText,
    backgroundImage,
    forecastLogo,
    primaryCTAColor,
    primaryCTAType,
  } = props?.fields || {};

  const [selectedPlace, setSelectedPlace] = useState<LocationSearchData>();
  const [showLocationError, setShowLocationError] = useState(false);

  const { sitecoreContext } = useSitecoreContext();
  const globalContext = useContext(GlobalContext);
  const i18n = useI18n();
  const router = useRouter();
  const latLongMsg = i18n.t('LatLongRequired');
  const failedToFetchMsg = i18n.t('FailedToFetch');
  const locationReqMsg = i18n.t('LocationRequired');

  useEffect(() => {
    const scrollToResult = () => {
      const toBeScrolled = document.getElementById('forecastResultSection');
      const header =
        typeof window !== 'undefined' ? window.document.getElementById('header') : null;

      if (toBeScrolled) {
        const targetRect = toBeScrolled.getBoundingClientRect();
        const scrollPosition = targetRect.top;

        if (header) {
          const currentScrollPosition = window.scrollY || window.pageYOffset;
          const newPosition = currentScrollPosition + scrollPosition - header?.offsetHeight;
          window.scrollTo({
            top: newPosition,
            behavior: 'smooth',
          });
        }
      } else setTimeout(scrollToResult, 1000);
    };

    if (
      globalContext?.forecastData &&
      globalContext?.forecastData.rawIndexValue &&
      globalContext?.forecastData?.rawIndexValue?.length
    ) {
      scrollToResult();
      setSelectedPlace(globalContext?.locationSearchData);
    }
  }, [globalContext?.forecastData]);

  if (!props.fields) return <>MosquitoForecastPromo Component</>;

  const {
    promoContainer,
    componentTitle,
    bgImage,
    mainContainer,
    bgContainer,
    offCastImage,
    componentDescription,
    formContainer,
    searchInputContainer,
    locationErrorText,
  } = tailwindVariants({ showError: !selectedPlace && showLocationError ? true : false });

  const handleClick = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (!selectedPlace) {
      setShowLocationError(true);
      return;
    }
    getForcastData();
  };

  const getForcastData = async () => {
    const { latitude, longitude } = selectedPlace as LocationSearchData;
    const { mosquitoForecastUrl } = sitecoreContext?.weatherCompanySettings as ForecastAPIConfig;
    try {
      const payload = {
        apiURL: mosquitoForecastUrl,
        latitude,
        longitude,
        latLongMsg,
        failedToFetchMsg,
      };

      const { data } = await axios.get('/api/climatecompany/mosquitoforecast', {
        params: payload,
      });

      globalContext?.setForecastData(data.data);
      globalContext?.setLocationSearchData(selectedPlace as LocationSearchData);

      if (primaryCTA?.value.href) router.push(primaryCTA.value.href);
    } catch (error) {
      console.error(failedToFetchMsg, error);
    }
  };

  const id = props?.params?.RenderingIdentifier;
  return (
    <div
      className={mainContainer()}
      data-component="authorable/mosquitoforecastpromo"
      id={id ? id : undefined}
    >
      {backgroundImage?.value?.src && (
        <div className={bgContainer()}>
          <ImageWrapper className={bgImage()} field={backgroundImage} />
        </div>
      )}
      <Container className={promoContainer()} id="promoSection">
        {forecastLogo?.value?.src && (
          <ImageWrapper className={offCastImage()} field={forecastLogo} />
        )}
        <div>
          {title?.value && (
            <div className={componentTitle()}>
              <Text encode={false} field={title} tag="h2" />
            </div>
          )}
          {description?.value && (
            <div className={componentDescription()}>
              <RichText field={description} />
            </div>
          )}
        </div>
        <form className={formContainer()} onSubmit={handleClick}>
          <div className={searchInputContainer()}>
            <OffcastSearchInput
              onSelectLocation={setSelectedPlace}
              placeholder={searchPlaceholderText?.value as string}
              selectedPlace={selectedPlace}
              isError={!selectedPlace && showLocationError}
            />
            <p className={locationErrorText()}>{locationReqMsg}</p>
          </div>
          {(primaryCTA?.value?.text || primaryCTAText?.value) && (
            <Button
              //As the design requires a filled CTA, we do not need to add a fallback since
              //the button component has a default variant that displays a filled CTA.
              label={primaryCTA?.value?.text || primaryCTAText?.value}
              href={primaryCTA?.value.href}
              type={primaryCTAType?.value as string}
              color={primaryCTAColor?.value}
            />
          )}
        </form>
      </Container>
    </div>
  );
};

export default MosquitoForecastPromo;
