// Global
import { Text } from '@sitecore-jss/sitecore-jss-nextjs';
import React from 'react';
import { tv } from 'tailwind-variants';

// Lib
import { ComponentProps } from 'lib/component-props';
import { useTheme } from 'lib/context/ThemeContext';
import { HeroComponents } from 'lib/templates/Feature.Dart.model';
import { ComponentVariants } from 'lib/context/ComponentVariants';

// Local
import Container from 'components/authorable/Layout/DartContainer/DartContainer';
import Button from 'helpers/Button/Button';
import LinkWrapper from 'helpers/LinkWrapper/LinkWrapper';
import RichTextA11yWrapper from 'helpers/RichTextA11yWrapper/RichTextA11yWrapper';
import SVG from 'helpers/SVG/SVG';
import LegalDisclaimer from 'helpers/LegalDisclaimer/LegalDisclaimer';

export type HeroInternalProps = ComponentProps &
  HeroComponents.HeroInternal.HeroInternal & {
    theme?: string;
  };

type Alignment = 'Left' | 'Center' | undefined;

type ShowPattern = true | false | undefined;

const tailwindVariants = tv({
  slots: {
    base: [
      'component-content',
      'flex',
      'flex-col',
      'h-full',
      'gap-components-hero-internal-spacing-small-button-margin-top',
      'justify-center',
      'min-h-components-hero-internal-spacing-small-min-height',
      'md:min-h-components-hero-internal-spacing-large-min-height',
      'overflow-hidden',
      'px-components-accordion-spacing-small-padding-x',
      'py-components-hero-internal-spacing-small-padding-y',
      'relative',
      'md:pr-components-hero-internal-spacing-large-padding-right',
      'md:px-components-accordion-spacing-large-padding-x',
      'md:py-components-hero-internal-spacing-large-padding-y',
    ],
    descriptionText: [
      'font-bodySans-medium',
      'leading-bodySans-medium',
      'line-clamp-2',
      'text-bodySans-large',
      'text-bodySans-medium',
      'text-components-hero-internal-color-default-body',
      'md:font-bodySans-large',
      'md:leading-bodySans-large',
      'md:line-clamp-none',
      'md:text-bodySans-large',
      'pt-[4px]',
      'border-b-4',
      'pb-[4px]',
      'border-transparent',
      'max-h-[50px]',
    ],
    svgBackground: [
      'absolute',
      'left-0',
      'top-0',
      'w-full',
      'h-full',
      'overflow-hidden',
      'hidden',
      '[&>*]:h-full',
      '[&>*]:top-0',
      '[&>*]:w-full',
      '[&>*]:absolute',
    ],
    svgDecor: ['w-full', 'absolute', 'bottom-0', 'left-0', 'z-10', 'hidden'],
    textWrapper: [
      'field-heroInternalTitle',
      'flex',
      'flex-col',
      'gap-components-hero-internal-spacing-small-title-margin-bottom',
      'items-start',
      'relative',
      'text-components-hero-internal-color-default-title',
    ],
    titleText: [
      'font-header-small-large',
      'leading-header-small-large',
      'text-header-small-large',
      'md:font-header-large-large',
      'md:leading-header-large-large',
      'md:text-header-large-large',
    ],
  },
  variants: {
    alignment: {
      Center: {
        base: ['items-center'],
        descriptionText: ['text-center'],
        titleText: ['text-center'],
        textWrapper: ['items-center'],
      },
      Left: {},
    },
    showPattern: {
      true: {
        base: [
          'bg-components-hero-internal-color-accent-bg',
          'md:bg-components-hero-internal-color-default-bg',
        ],
        svgBackground: ['hidden', 'md:block'],
        svgDecor: ['hidden', 'md:block'],
      },
      false: {
        base: ['bg-components-hero-internal-color-default-bg'],
      },
    },
  },
});

const HeroInternal = (props: HeroInternalProps): JSX.Element => {
  const {
    description,
    enablePattern,
    primaryCTA,
    title,
    disclaimerText,
    primaryCTAColor,
    primaryCTAType,
  } = props?.fields || {};
  const { alignContent } = props?.params || {};
  const { dataSource, componentName } = props?.rendering || {};

  const { themeName } = useTheme();
  const componentVariants = ComponentVariants();

  const alignment = alignContent !== 'Left' && 'Center';
  const showPattern = enablePattern?.value ? true : false;

  const { base, titleText, descriptionText, svgBackground, svgDecor, textWrapper } =
    tailwindVariants({
      alignment: alignment as Alignment,
      showPattern: showPattern as ShowPattern,
    });

  if (!props?.fields) return <></>;

  return (
    <Container fullWidth>
      <div className={base()} data-component="authorable/heroes/internalhero">
        {componentVariants?.heroInternal?.hasDecor && (
          <SVG className={svgDecor()} svg={`hero-internal/Decor,Brand=${themeName}`} />
        )}
        <SVG className={svgBackground()} svg={`hero-internal/BG,Brand=${themeName}`} />
        <div className={`${textWrapper()} z-20`}>
          <Text className={titleText()} encode={false} field={title} tag="h2" />
          {props.fields?.description && (
            <RichTextA11yWrapper className={descriptionText()} field={description} />
          )}
          {primaryCTA?.value?.href && (
            <Button
              href={primaryCTA?.value?.href}
              label={primaryCTA?.value?.text}
              tag="a"
              color={primaryCTAColor?.value || 'white'}
              type={primaryCTAType?.value || 'filled'}
              size="compressed"
              gtmEvent={{
                event: 'cta_click',
                type: 'primary',
                'gtm.element.dataset.gtmLinkUrl': primaryCTA?.value?.href,
                'gtm.element.dataset.gtmLinkName': primaryCTA?.value?.text,
                'gtm.element.dataset.gtmDatasourceId': dataSource,
                'gtm.element.dataset.gtmComponentName': componentName,
              }}
            >
              <LinkWrapper field={primaryCTA} />
            </Button>
          )}
          {disclaimerText?.value != '' && <LegalDisclaimer disclaimerText={disclaimerText} />}
        </div>
      </div>
    </Container>
  );
};
export default HeroInternal;
