// Global
import { useRouter } from 'next/router';
import React, { useEffect, useRef, useState } from 'react';

// Lib
import { ComponentProps } from 'lib/component-props';
import { CardComponents, SearchComponents } from 'lib/templates/Feature.Dart.model';
import { ItemEx } from 'lib/templates/_.Sitecore.Override';

// Local
import Container from 'components/authorable/Layout/DartContainer/DartContainer';
import ProductListWithSearchWidget from 'components/ProductListWithSearch';
import { useRealPathName } from 'lib/utils/use-real-pathname';
import { sendGTMEvent } from '@next/third-parties/google';

export type ProductResultsListProps = ComponentProps &
  SearchComponents.ProductResultsListWithSearch.ProductResultsListWithSearch;

export type Badge = ItemEx & CardComponents.ProductCard.Badge;

export const ProductResultsListwithSearch = (props: ProductResultsListProps): JSX.Element => {
  const [count, setCount] = useState(0);

  const router = useRouter();
  const path = useRealPathName();

  useEffect(() => {
    setCount((c) => c + 1);
  }, [router]);

  // Add ref for the GTM Event
  const hasFired = useRef(false);

  useEffect(() => {
    // Function to send the GTM event
    sendGTMEvent({ event: 'switcher', type: 'click', value: 'grid' });
    hasFired.current = true;
  }, []); // Empty dependency array ensures it runs only once on mount

  if (!props?.fields) return <></>;

  const productResultsData = {
    resultsPerPage: props?.fields?.resultsPerPage,
    noSearchResult: props?.fields?.noSearchResult,
    filterLabel: props?.fields?.filterLabel,
    sortLabel: props?.fields?.sortLabel,
    filterButtonLabel: props?.fields?.filterButtonLabel,
    sortByLabel: props?.fields?.sortByLabel,
    primaryCTAColor: props?.fields?.primaryCTAColor,
    primaryCTAType: props?.fields?.primaryCTAType,
  };

  // Unique id for component
  const id = props?.params?.RenderingIdentifier;

  return (
    <Container>
      <div data-component="layout/productresultslistwithsearch" id={id ? id : undefined}>
        <div>
          <ProductListWithSearchWidget
            rfkId={'rfkid_12'}
            {...productResultsData}
            key={path + count}
            isBadgeFullWidth={props?.params?.isBadgeFullWidth}
          />
        </div>
      </div>
    </Container>
  );
};

export default ProductResultsListwithSearch;
